import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  type UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { AlertContext } from "../contexts/alert-context";
import { StoreVendorContext } from "../contexts/store-vendor-context";
import type { Vendor } from "../data/vendor";
import { StoreService } from "../services/stores";
import { useSelectedStore } from "./selected-store";

export function useVendorsQuery(): UseSuspenseQueryResult<Vendor[], Error> {
  const { getAccessTokenSilently } = useAuth0();
  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );
  const { selectedStore } = useSelectedStore();

  return useSuspenseQuery({
    queryKey: ["stores", selectedStore?.id, "vendors"],
    queryFn: () => {
      if (!selectedStore) {
        return new Promise((resolve, _reject) => {
          setTimeout(() => {
            resolve([]);
          }, 2000);
        });
      }
      // return Promise.reject("Failed to fetch vendors");
      return storeService.getVendorsByStore(selectedStore.id);
    },
  });
}

export function useVendors() {
  const { addErrorAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { selectedStore } = useSelectedStore();

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    setLoading(true);
    // flush previous vendors so we don't show stale data during fetch
    setVendors([]);
    storeService
      .getVendorsByStore(selectedStore.id)
      .then((vendors) => setVendors(vendors))
      .catch((error: Error) => {
        setError(error.message);
        addErrorAlert(`Invalid vendors: ${error.message}`);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedStore, storeService, addErrorAlert]);

  const updateVendor = useCallback(
    (vendor: Vendor) => {
      setLoading(true);
      return storeService
        .updateVendor(vendor.store_id, vendor)
        .then((updatedVendor: Vendor) => {
          setVendors((vendors: Vendor[]) => {
            return vendors.map((v) =>
              v.vendor_id === updatedVendor.vendor_id ? updatedVendor : v,
            );
          });
          return updatedVendor;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addErrorAlert],
  );

  return { vendors, loading, error, updateVendor };
}

export function useVendor(id: string | undefined) {
  const { vendors, loading } = useContext(StoreVendorContext);
  const vendor = useMemo(
    () => vendors.find((vendor) => vendor.vendor_id === id),
    [vendors, id],
  );
  return { loading, vendor };
}
