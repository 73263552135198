import * as Yup from "yup";

// form validation rules
export const RepresentativeSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),

  division: Yup.string().defined(),

  email: Yup.string().email().required(),
  work_phone: Yup.string().defined(),
  mobile_phone: Yup.string().defined(),

  send_order: Yup.boolean().required(),
});

export type Representative = Yup.InferType<typeof RepresentativeSchema>;
