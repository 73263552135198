import * as Yup from "yup";
import { SalesTotalSchema } from "./sales-total";
import { YearSalesSchema } from "./year-sales";

export const CurrentYearEstimatesSchema = Yup.object().shape({
  totals: SalesTotalSchema.required(),
  monthly: Yup.array().of(SalesTotalSchema).required(),
});

export type CurrentYearEstimates = Yup.InferType<
  typeof CurrentYearEstimatesSchema
>;

// form validation rules
export const StoreSalesStatsSchema = Yup.object().shape({
  historical: Yup.array().of(YearSalesSchema).required(),
  current_year_estimates: CurrentYearEstimatesSchema.required(),
  last_transaction_date: Yup.date(),
});

export type StoreSalesStat = Yup.InferType<typeof StoreSalesStatsSchema>;
