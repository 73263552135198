import * as Yup from "yup";

import { AddressSchema } from "./address";
import { OrderSettingsSchema } from "./order-settings";

// form validation rules
export const VendorSchema = Yup.object().shape({
  vendor_id: Yup.string().required(),
  store_id: Yup.string().required(),
  name: Yup.string().required(),
  normalized_name: Yup.string().defined(),
  account_number: Yup.string().defined(),
  terms: Yup.string().defined(),
  notes: Yup.string().defined(),
  invoice_formats: Yup.array().of(Yup.string().required()).required(),
  order_settings: OrderSettingsSchema.required(),
  address: AddressSchema,
  disabled: Yup.boolean().required(),

  updated_at: Yup.date().required(),
});

export type Vendor = Yup.InferType<typeof VendorSchema>;
