import { Link, type LinkProps } from "react-router-dom";

type ReactButton = React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>>;

export const PrimaryButton: ReactButton = (properties) => (
  <button
    className="py-2 px-4 bg-emerald-500 text-white rounded hover:bg-emerald-700 transition duration-150 flex gap-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
    {...properties}
  />
);

export const SecondaryButton: ReactButton = (properties) => (
  <button
    type="button"
    className="py-2 px-4 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 rounded hover:bg-gray-50 flex gap-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
    {...properties}
  />
);

export const SmallSecondaryButton: ReactButton = (properties) => (
  <button
    type="button"
    className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    {...properties}
  />
);

export const DangerButton: ReactButton = (properties) => (
  <button
    className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-700"
    {...properties}
  />
);

export const SmallDangerButton: ReactButton = (properties) => (
  <button
    className="py-2 px-4 bg-red-500 text-sm text-white rounded hover:bg-red-700"
    {...properties}
  />
);

export const PrimaryLinkButton: React.FC<LinkProps> = (properties) => (
  <Link
    className="py-2 px-4 bg-emerald-500 text-white rounded hover:bg-emerald-700 transition duration-150 disabled:bg-gray-400 disabled:cursor-not-allowed"
    {...properties}
  />
);

export const SecondaryLinkButton: React.FC<LinkProps> = (properties) => (
  <Link
    className="flex py-2 px-4 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 rounded hover:bg-gray-50 disabled:bg-gray-400 disabled:cursor-not-allowed"
    {...properties}
  />
);

export const LinkButton: React.FC<LinkProps> = (properties) => (
  <Link
    className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700"
    {...properties}
  />
);

type AnchorProperties = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const ExternalLinkButton: React.FC<AnchorProperties> = (properties) => (
  <a
    className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700"
    {...properties}
  />
);

export const SmallLinkButton: React.FC<LinkProps> = (properties) => (
  <Link
    className="p-2 bg-emerald-500 text-sm text-white rounded hover:bg-emerald-700"
    {...properties}
  />
);

export default { PrimaryButton, DangerButton, SmallLinkButton, LinkButton };
