import * as React from "react";

import type { CreateOrder, Order } from "../data/order";
import { useOrders } from "../hooks/orders";

type StoreOrderContext = {
  orders: Order[];
  loading: boolean;
  error: string | undefined;
  createOrder: (storeId: string, order: CreateOrder) => Promise<Order>;
  deleteOrder: (order: Order) => Promise<boolean>;
  submitOrder: (
    storeId: string,
    order: Order,
    method: string,
  ) => Promise<Order>;
  getOrder: (storeId: string, orderId: string) => Promise<Order>;
};

export const StoreOrderContext = React.createContext<StoreOrderContext>({
  orders: [],
  loading: true,
  error: undefined,
  createOrder: () => Promise.reject(),
  submitOrder: () => Promise.reject(),
  deleteOrder: () => Promise.resolve(false),
  getOrder: () => Promise.reject(),
});

export const StoreOrderContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    loading,
    error,
    orders,
    deleteOrder,
    createOrder,
    submitOrder,
    getOrder,
  } = useOrders();

  return (
    <StoreOrderContext.Provider
      value={{
        loading,
        error,
        orders,
        createOrder,
        deleteOrder,
        submitOrder,
        getOrder,
      }}
    >
      {children}
    </StoreOrderContext.Provider>
  );
};
