import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { create } from "zustand";

type SelectedRowState = {
  selectedRows: { [key: string]: string | null };
  setRouteSelection: (routeKey: string, selection: string | null) => void;
};

const useSelectedRow = create<SelectedRowState>((set) => ({
  selectedRows: {},
  setRouteSelection: (routeKey: string, selection: string | null) =>
    set((state: SelectedRowState) => ({
      selectedRows: { ...state.selectedRows, [routeKey]: selection },
    })),
}));

/**
 * Hook to manage row highlighting by route key and an item id.
 */
export const useRowHighlighting = (itemId: string) => {
  const location = useLocation();
  const { selectedRows, setRouteSelection } = useSelectedRow();
  const isHighlighted = useMemo(
    () => selectedRows[location.pathname] === itemId,
    [selectedRows, itemId, location],
  );
  const onRowClick = useCallback(() => {
    setRouteSelection(location.pathname, isHighlighted ? null : itemId);
  }, [setRouteSelection, location, isHighlighted, itemId]);

  return { isHighlighted, onRowClick };
};
