import { useCallback, useContext, useEffect, useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { StoreContext } from "../contexts/store-context";
import type { Store } from "../data/store";

export const useSelectedStore = () => {
  const { loading, stores } = useContext(StoreContext);
  const match = useMatch("/stores/:id/*");
  const navigate = useNavigate();

  const selectedStore = useMemo(() => {
    const storeId = match?.params.id;

    return stores.find((store) => store.id === storeId);
  }, [stores, match]);

  useEffect(() => {
    if (selectedStore) {
      document.title = `${selectedStore.name} | Orderbot`;
    }

    if (!loading && !selectedStore && match?.params.id !== "add") {
      if (stores.length > 0) {
        navigate(`/stores/${stores[0].id}`);
      } else {
        navigate("/stores/add");
      }
    }
  }, [loading, stores, selectedStore, match, navigate]);

  const setSelectedStore = useCallback(
    (store: Store) => {
      // maintain the current path if we're on a known sub-route of stores
      navigate(`/stores/${store.id}/${match?.params["*"] ?? ""}`);
    },
    [navigate, match],
  );

  return { loading, selectedStore, setSelectedStore };
};
