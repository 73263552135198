import * as React from "react";

import type { Vendor } from "../data/vendor";
import { useVendors } from "../hooks/vendors";

type StoreVendorContext = {
  vendors: Vendor[];
  loading: boolean;
  error: string | undefined;
  updateVendor: (vendor: Vendor) => Promise<Vendor>;
};

export const StoreVendorContext = React.createContext<StoreVendorContext>({
  vendors: [],
  loading: true,
  error: undefined,
  updateVendor: () => Promise.reject("Not implemented"),
});

export const StoreVendorContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { loading, error, vendors, updateVendor } = useVendors();

  return (
    <StoreVendorContext.Provider
      value={{
        loading,
        error,
        vendors,
        updateVendor,
      }}
    >
      {children}
    </StoreVendorContext.Provider>
  );
};
