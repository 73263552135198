import { PrinterIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import { PrimaryButton } from "../../components/buttons";
import { PrintContainer } from "../../components/print-container";
import { PrintTable } from "../../components/printable-table";
import {
  HideDisabledFilter,
  NotSoldInFilter,
  SearchField,
  SoldInFilter,
  TotalValue,
} from "../../components/product-table/components";
import {
  Actions,
  ExtendedCost,
  ItemCode,
  ProductName,
  QOH,
  Reorder,
  Size,
  Type,
  VendorName,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product, SaleStatDays } from "../../data/product";
import { type Filters, useFilteredProducts } from "../../hooks/products";

const defaultFilters: Filters = {
  soldIn: 365,
  notSoldIn: 180,
  minimumInventory: 1,
};
const soldInDays: SaleStatDays[] = [90, 180, 365];
const notSoldInDays: SaleStatDays[] = [30, 60, 90, 180];

export function UnderperformingInventoryReport({
  products,
}: {
  products: Product[];
}) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );
  const columns = useMemo(() => {
    const soldInColumn = getUnitsSoldColumn(filters.soldIn || 365);
    const notSoldInColumn = getUnitsSoldColumn(filters.notSoldIn || 180);

    return [
      VendorName,
      ItemCode,
      ProductName,
      Type,
      Size,
      soldInColumn,
      notSoldInColumn,
      QOH,
      Reorder,
      ExtendedCost,
      Actions,
    ];
  }, [filters]);

  const [printing, setPrinting] = useState(false);

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <TotalValue className="mr-auto" />
          <HideDisabledFilter />
          <SoldInFilter overrideSoldInDays={soldInDays} />
          <NotSoldInFilter overrideSoldInDays={notSoldInDays} />
          <SearchField />
          <PrimaryButton onClick={() => setPrinting(true)}>
            <PrinterIcon className="h-5 w-5" aria-hidden="true" />
          </PrimaryButton>
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={filteredProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
      {printing && (
        <PrintContainer onAfterPrint={() => setPrinting(false)}>
          <PrintTable columns={columns} items={filteredProducts} />
        </PrintContainer>
      )}
    </>
  );
}
