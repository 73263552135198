import * as Yup from "yup";

// form validation rules
export const SalesTotalSchema = Yup.object().shape({
  costs: Yup.number().required(),
  sales: Yup.number().required(),
  taxes: Yup.number().required(),
  transactions: Yup.number().required(),
});

export type SalesTotal = Yup.InferType<typeof SalesTotalSchema>;
