export const LandingNav = () => {
  return (
    <>
      <a
        href="#features"
        className="text-white hover:underline underline-offset-4"
      >
        Features
      </a>
      <a
        href="#pricing"
        className="text-white hover:underline underline-offset-4"
      >
        Pricing
      </a>
      <a
        href="#testimonials"
        className="text-white hover:underline underline-offset-4"
      >
        Testimonials
      </a>
      <a href="#team" className="text-white hover:underline underline-offset-4">
        Team
      </a>
    </>
  );
};
