import { PrinterIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { PrimaryButton } from "../../components/buttons";
import { PrintContainer } from "../../components/print-container";
import { PrintTable } from "../../components/printable-table";
import {
  HideDisabledFilter,
  SearchField,
} from "../../components/product-table/components";
import {
  Actions,
  ItemCode,
  ProductName,
  QOH,
  Reorder,
  Size,
  Type,
  VendorName,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product } from "../../data/product";
import { useFilteredProducts } from "../../hooks/products";

const columns: ColumnDefinition<Product>[] = [
  VendorName,
  ItemCode,
  ProductName,
  Type,
  Size,
  QOH,
  getUnitsSoldColumn(90),
  Reorder,
  Actions,
];

const defaultFilters = { maximumInventory: -1 };

export function NegativeInventoryReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );
  const [printing, setPrinting] = useState(false);

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <HideDisabledFilter />
          <SearchField />
          <PrimaryButton onClick={() => setPrinting(true)}>
            <PrinterIcon className="h-5 w-5" aria-hidden="true" />
          </PrimaryButton>
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={filteredProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
      {printing && (
        <PrintContainer onAfterPrint={() => setPrinting(false)}>
          <PrintTable columns={columns} items={filteredProducts} />
        </PrintContainer>
      )}
    </>
  );
}
