import { type FC, type ReactNode, useMemo } from "react";
import type { CurrentYearEstimates } from "../../data/store-sales-stat";
import type { YearSales } from "../../data/year-sales";
import { combinedCurrencyFormatter } from "../../helpers/format-currency";
import {
  lastYearColorTailwind,
  thisYearColorTailwind,
  thisYearEstimatesColorTailwind,
} from "./sales-widget-constants";

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
});

const formatNumber = (amount: number) => {
  return numberFormatter.format(amount);
};

const formatPercent = (percent: number) => {
  const formatted = `${(percent * 100).toFixed(1)}%`;
  if (percent < 0) {
    return `${formatted}`;
  }

  return `+${formatted}`;
};

const useCurrentMonth = (lastImported: Date) => {
  const index = useMemo(() => {
    return lastImported.getMonth();
  }, [lastImported]);
  const monthString = useMemo(() => {
    return monthNames[index];
  }, [index]);
  return { index, monthString };
};

type Timespan = "lastYear" | "thisYear" | "thisYearEstimates";

type SummaryTileProperties = {
  title: string;
  current?: number;
  previous?: number;
  format: "currency" | "number" | "percentage";
  timespan: Timespan;
};

const getBackgroundColor = (timespan: Timespan) => {
  switch (timespan) {
    case "lastYear": {
      return lastYearColorTailwind;
    }
    case "thisYear": {
      return thisYearColorTailwind;
    }
    case "thisYearEstimates": {
      return thisYearEstimatesColorTailwind;
    }
  }
};

const SummaryTile: FC<SummaryTileProperties> = ({
  title,
  current,
  previous,
  format,
  timespan,
}) => {
  const className = useMemo(() => {
    const color = getBackgroundColor(timespan);
    const textColor =
      timespan === "thisYearEstimates" ? "text-gray-600" : "text-white";
    return `grid grid-rows-[1fr,min-content,1fr] ${color} ${textColor} p-2 font-bold text-xl content-center gap-1`;
  }, [timespan]);

  const stat = useMemo(() => {
    if (current === undefined) {
      return "N/A";
    }
    switch (format) {
      case "currency": {
        return combinedCurrencyFormatter(current);
      }
      case "number": {
        return formatNumber(current);
      }
      case "percentage": {
        return formatPercent(current);
      }
    }
  }, [current, format]);

  const percentChange = useMemo(() => {
    if (!previous || current === undefined) {
      return;
    }

    if (format === "percentage") {
      return current - previous;
    }

    return (current - previous) / previous;
  }, [current, previous, format]);

  const percentChangeClassName = useMemo(() => {
    const textColor =
      percentChange !== undefined && percentChange < 0 ? "text-red-600" : "";
    return `text-base ${textColor}`;
  }, [percentChange]);

  return (
    <div className="grid grid-rows-[auto,1fr] text-center">
      <h3 className="text-xl font-bold p-2 text-gray-600">{title}</h3>
      <div className={className}>
        <div />
        <div>{stat}</div>
        {percentChange !== undefined && (
          <div className={percentChangeClassName}>
            {formatPercent(percentChange)} YoY
          </div>
        )}
      </div>
    </div>
  );
};

const monthNames = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SummaryHeader = ({ children }: { children: ReactNode }) => (
  <div className="text-lg font-bold pt-1 text-gray-600 text-center border-b border-gray-400">
    {children}
  </div>
);

type SalesSummaryProperties = {
  lastImported: Date;
  lastYearSales: YearSales | undefined;
  thisYearSales: YearSales;
  thisYearEstimates: CurrentYearEstimates;
};

export const MarginSummary: FC<SalesSummaryProperties> = ({
  lastImported,
  lastYearSales,
  thisYearSales,
  thisYearEstimates,
}) => {
  const thisYear = useMemo(() => thisYearSales.year, [thisYearSales]);

  const { index, monthString } = useCurrentMonth(lastImported);
  const thisYearMonth = useMemo(
    () => thisYearSales.monthly[index],
    [thisYearSales, index],
  );
  const lastYearMtdAmount = useMemo(() => {
    return (
      lastYearSales &&
      lastYearSales.totals_to_date_month.sales -
        lastYearSales.totals_to_date_month.costs
    );
  }, [lastYearSales]);

  const mtdAmount = useMemo(() => {
    return thisYearMonth.sales - thisYearMonth.costs;
  }, [thisYearMonth]);

  const mtdPercent = useMemo(() => {
    return mtdAmount / thisYearMonth.sales;
  }, [mtdAmount, thisYearMonth]);

  const lastYearMtdPercent = useMemo(() => {
    return (
      lastYearMtdAmount &&
      lastYearSales &&
      lastYearMtdAmount / lastYearSales.totals_to_date_month.sales
    );
  }, [lastYearMtdAmount, lastYearSales]);

  const thisYearTotalEstimate = useMemo(() => {
    return thisYearEstimates.totals.sales - thisYearEstimates.totals.costs;
  }, [thisYearEstimates]);

  const ytdAmount = useMemo(() => {
    return (
      thisYearSales.totals_to_date.sales - thisYearSales.totals_to_date.costs
    );
  }, [thisYearSales]);

  const ytdPercent = useMemo(() => {
    return ytdAmount / thisYearSales.totals_to_date.sales;
  }, [ytdAmount, thisYearSales]);

  const lastYearYtdAmount = useMemo(() => {
    return (
      lastYearSales &&
      lastYearSales.totals_to_date.sales - lastYearSales.totals_to_date.costs
    );
  }, [lastYearSales]);

  const lastYearYtdPercent = useMemo(() => {
    return (
      lastYearYtdAmount &&
      lastYearSales &&
      lastYearYtdAmount / lastYearSales.totals_to_date.sales
    );
  }, [lastYearYtdAmount, lastYearSales]);

  const thisMonthEstimate = useMemo(() => {
    return (
      thisYearEstimates.monthly[index].sales -
      thisYearEstimates.monthly[index].costs
    );
  }, [thisYearEstimates, index]);

  return (
    <div className="grid grid-flow-row">
      <SummaryHeader>{monthString}</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${thisYear} MTD $`}
          current={mtdAmount}
          previous={lastYearMtdAmount}
          timespan="thisYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYear} MTD %`}
          current={mtdPercent}
          previous={lastYearMtdPercent}
          timespan="thisYear"
          format="percentage"
        />
        <SummaryTile
          title={`${thisYear} Total ~`}
          current={thisMonthEstimate}
          timespan="thisYearEstimates"
          format="currency"
        />
      </div>
      <SummaryHeader>Year</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${thisYear} YTD $`}
          current={ytdAmount}
          previous={lastYearYtdAmount}
          timespan="thisYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYear} YTD %`}
          current={ytdPercent}
          previous={lastYearYtdPercent}
          timespan="thisYear"
          format="percentage"
        />
        <SummaryTile
          title={`${thisYear} Total ~`}
          current={thisYearTotalEstimate}
          timespan="thisYearEstimates"
          format="currency"
        />
      </div>
    </div>
  );
};

export const SalesSummary: FC<SalesSummaryProperties> = ({
  lastImported,
  lastYearSales,
  thisYearSales,
  thisYearEstimates,
}) => {
  const thisYear = useMemo(() => thisYearSales.year, [thisYearSales]);
  const lastYear = useMemo(() => thisYear - 1, [thisYear]);
  const { index, monthString } = useCurrentMonth(lastImported);
  return (
    <div className="grid grid-flow-row">
      <SummaryHeader>{monthString}</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${lastYear} MTD`}
          current={lastYearSales?.totals_to_date_month.sales}
          timespan="lastYear"
          format="currency"
        />
        <SummaryTile
          title={`${lastYear} Total`}
          current={lastYearSales?.monthly[index].sales}
          timespan="lastYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYear} MTD`}
          current={thisYearSales.monthly[index].sales}
          previous={lastYearSales?.totals_to_date_month.sales}
          timespan="thisYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYear} Total ~`}
          current={thisYearEstimates.monthly[index].sales}
          timespan="thisYearEstimates"
          format="currency"
        />
      </div>
      <SummaryHeader>Year</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${lastYear} YTD`}
          current={lastYearSales?.totals_to_date.sales}
          timespan="lastYear"
          format="currency"
        />
        <SummaryTile
          title={`${lastYear} Total`}
          current={lastYearSales?.totals.sales}
          timespan="lastYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYear} YTD`}
          current={thisYearSales.totals_to_date.sales}
          previous={lastYearSales?.totals_to_date.sales}
          timespan="thisYear"
          format="currency"
        />
        <SummaryTile
          title={`${thisYearSales.year} Total ~`}
          current={thisYearEstimates.totals.sales}
          timespan="thisYearEstimates"
          format="currency"
        />
      </div>
    </div>
  );
};

export const TransactionsSummary: FC<SalesSummaryProperties> = ({
  lastImported,
  lastYearSales,
  thisYearSales,
  thisYearEstimates,
}) => {
  const thisYear = useMemo(() => thisYearSales.year, [thisYearSales]);
  const lastYear = useMemo(() => thisYear - 1, [thisYear]);
  const { index, monthString } = useCurrentMonth(lastImported);
  const lastYearAverageMonthTicket = useMemo(() => {
    return (
      lastYearSales &&
      lastYearSales.monthly[index].sales /
        lastYearSales.monthly[index].transactions
    );
  }, [lastYearSales, index]);
  const monthAverageTicket = useMemo(() => {
    return (
      thisYearSales.totals_to_date_month.sales /
      thisYearSales.totals_to_date_month.transactions
    );
  }, [thisYearSales]);

  const lastYearAverageTicket = useMemo(() => {
    return (
      lastYearSales &&
      lastYearSales.totals_to_date.sales /
        lastYearSales.totals_to_date.transactions
    );
  }, [lastYearSales]);
  const yearAverageTicket = useMemo(() => {
    return thisYearSales.totals.sales / thisYearSales.totals.transactions;
  }, [thisYearSales]);

  return (
    <div className="grid grid-flow-row">
      <SummaryHeader>{monthString}</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${lastYear} MTD`}
          current={lastYearSales?.totals_to_date_month.transactions}
          timespan="lastYear"
          format="number"
        />
        <SummaryTile
          title={`${thisYearSales.year} MTD`}
          current={thisYearSales.monthly[index].transactions}
          previous={lastYearSales?.totals_to_date_month.transactions}
          timespan="thisYear"
          format="number"
        />
        <SummaryTile
          title={`${thisYearSales.year} Total ~`}
          current={thisYearEstimates.monthly[index].transactions}
          timespan="thisYearEstimates"
          format="number"
        />
        <SummaryTile
          title="$ / Ticket"
          current={monthAverageTicket}
          previous={lastYearAverageMonthTicket}
          timespan="thisYear"
          format="currency"
        />
      </div>
      <SummaryHeader>Year</SummaryHeader>
      <div className="grid grid-flow-col">
        <SummaryTile
          title={`${lastYear} YTD`}
          current={lastYearSales?.totals_to_date.transactions}
          timespan="lastYear"
          format="number"
        />
        <SummaryTile
          title={`${thisYearSales.year} YTD`}
          current={thisYearSales.totals_to_date.transactions}
          previous={lastYearSales?.totals_to_date.transactions}
          timespan="thisYear"
          format="number"
        />
        <SummaryTile
          title={`${thisYearSales.year} Total ~`}
          current={thisYearEstimates.totals.transactions}
          timespan="thisYearEstimates"
          format="number"
        />
        <SummaryTile
          title="$ / Ticket"
          current={yearAverageTicket}
          previous={lastYearAverageTicket}
          timespan="thisYear"
          format="currency"
        />
      </div>
    </div>
  );
};
