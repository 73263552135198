import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AlertContext } from "../contexts/alert-context";
import type { Store } from "../data/store";
import { StoreService } from "../services/stores";

export const useStores = () => {
  const { addErrorAlert } = useContext(AlertContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [stores, setStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    storeService
      .getAll()
      .then((storeResults: Store[]) => {
        setStores(storeResults);
        if (storeResults.length === 0) {
          setError("No stores found");
        } else {
          setError(undefined);
        }
      })
      .catch((error: Error) => {
        setError(error.message);
        addErrorAlert(`Invalid store: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });

    () => {
      // not necessary to cancel pending requests
    };
  }, [isAuthenticated, storeService, addErrorAlert]);

  const updateStore = useCallback(
    (store: Store) => {
      setLoading(true);
      return storeService
        .update(store.id, store)
        .then((updatedStore: Store) => {
          setStores((stores: Store[]) => {
            return stores.map((s) =>
              s.id === updatedStore.id ? updatedStore : s,
            );
          });
          return updatedStore;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addErrorAlert],
  );

  return { loading, error, stores, updateStore };
};
