import { ExternalLinkButton } from "./buttons";

export const uploaderUrl =
  "https://s3-us-west-2.amazonaws.com/orderbot-uploader-releases/OrderbotSyncSetup.exe";

export const DownloadUploaderButton = () => (
  <ExternalLinkButton href={uploaderUrl}>
    Download the uploader
  </ExternalLinkButton>
);
