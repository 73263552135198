import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AlertContext } from "../contexts/alert-context";
import { StoreOrderContext } from "../contexts/store-order-context";
import type { CreateOrder, Order } from "../data/order";
import type { OrderItem } from "../data/order-item";
import type { OrderOptions } from "../data/order-options";
import { StoreService } from "../services/stores";
import { useSelectedStore } from "./selected-store";

export function useOrders() {
  const { addErrorAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [orders, setOrders] = useState<Order[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { selectedStore } = useSelectedStore();

  const createOrder = useCallback(
    (storeId: string, order: CreateOrder) => {
      setLoading(true);
      return storeService
        .createOrder(storeId, order)
        .then((newOrder) => {
          setOrders([...orders, newOrder]);
          return newOrder;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, addErrorAlert],
  );

  const submitOrder = useCallback(
    (storeId: string, order: Order, method: string) => {
      setLoading(true);
      return storeService
        .submitOrder(storeId, order, method)
        .then((updatedOrder) => {
          setOrders([...orders, updatedOrder]);
          return updatedOrder;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, addErrorAlert],
  );

  const saveOrderOptions = useCallback(
    (storeId: string, orderId: string, options: OrderOptions) => {
      setLoading(true);
      return storeService
        .saveOrderOptions(storeId, orderId, options)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addErrorAlert],
  );

  const deleteOrder = useCallback(
    (order: Order) => {
      setLoading(true);
      return storeService
        .deleteOrder(order)
        .then(() => {
          const updatedOrders = orders.filter((o) => o.id !== order.id);
          setOrders(updatedOrders);
          return true;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, addErrorAlert],
  );

  const getOrder = useCallback(
    (storeId: string, orderId: string) => {
      setLoading(true);
      return storeService
        .getOrderByStore(storeId, orderId)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addErrorAlert],
  );

  const saveOrderItem = useCallback(
    (storeId: string, orderId: string, orderItem: OrderItem) => {
      setLoading(true);
      return storeService
        .updateOrderItem(storeId, orderId, orderItem)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, addErrorAlert],
  );

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    setLoading(true);
    // flush previous orders so we don't show stale data during fetch
    setOrders([]);
    storeService
      .getOrdersByStore(selectedStore.id)
      .then((orders) => setOrders(orders))
      .catch((error: Error) => {
        setError(error.message);
        addErrorAlert(`Invalid orders: ${error.message}`);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedStore, storeService, addErrorAlert]);

  return {
    orders,
    loading,
    error,
    deleteOrder,
    createOrder,
    submitOrder,
    getOrder,
    saveOrderItem,
    saveOrderOptions,
  };
}

export function useOrder(storeId: string | undefined, id: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [order, setOrder] = useState<Order | undefined>();
  const { getOrder } = useContext(StoreOrderContext);

  const loadOrder = useCallback(
    (storeId: string, id: string) => {
      setLoading(true);
      setOrder(undefined);
      return getOrder(storeId, id)
        .then((order) => {
          setOrder(order);
          setError(undefined);
          return order;
        })
        .catch((error: Error) => {
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getOrder],
  );

  useEffect(() => {
    if (!storeId || !id) return;

    loadOrder(storeId, id);
  }, [loadOrder, storeId, id]);

  return { loading, order, error };
}
