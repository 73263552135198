type Properties = {
  components: React.FC<{
    children: React.ReactNode;
  }>[];
  children: React.ReactNode;
};

export function Compose(properties: Properties) {
  const { components = [], children } = properties;

  return (
    <>
      {components.reduceRight((accumulator, Comp) => {
        // biome-ignore lint/correctness/useJsxKeyInIterable: we don't need keys since we're nesting, not iterating
        return <Comp>{accumulator}</Comp>;
      }, children)}
    </>
  );
}
