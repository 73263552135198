import { useMemo } from "react";
import { Cell, Row, Table } from "../tables";
import type { ColumnDefinition } from "../virtualized-table/column-definition";

type PrintTableProperties<T> = {
  items: T[];
  columns: ColumnDefinition<T>[];
};

export function PrintTable<T>({ items, columns }: PrintTableProperties<T>) {
  const croppedItems = useMemo(() => items.slice(0, 1000), [items]);

  return (
    <div>
      {items.length > croppedItems.length && (
        <div className="bg-red-500 text-white text-center bold p-2 w-full">
          Only the first 1000 items are shown, consider using filters
        </div>
      )}
      <Table headings={columns.map((column) => column.label)}>
        {croppedItems.map((item, index) => (
          <Row key={index}>
            {columns.map((column, index) => (
              <Cell key={index}>{column.toString(item)}</Cell>
            ))}
          </Row>
        ))}
      </Table>
    </div>
  );
}
