import { useState } from "react";
import { NavLink } from "react-router-dom";
import type { Store } from "../../data/store";
import { Modal } from "../modal";
import { ReceiveInvoices } from "../receive-invoices";
import { activeClassName, className } from "./main-nav";

export const AppNav: React.FC<{ store: Store }> = ({ store }) => {
  const [receiveInvoicesIsOpen, setReceiveInvoicesIsOpen] = useState(false);

  return (
    <>
      <NavLink
        to={`/stores/${store.id}`}
        className={({ isActive }) => (isActive ? activeClassName : className)}
        end
      >
        Dashboard
      </NavLink>
      <NavLink
        to={`/stores/${store.id}/vendors`}
        className={({ isActive }) => (isActive ? activeClassName : className)}
      >
        Vendors
      </NavLink>
      <NavLink
        to={`/stores/${store.id}/products`}
        className={({ isActive }) => (isActive ? activeClassName : className)}
      >
        Products
      </NavLink>
      <NavLink
        to={`/stores/${store.id}/reports`}
        className={({ isActive }) => (isActive ? activeClassName : className)}
      >
        Reports
      </NavLink>
      <NavLink
        to={`/stores/${store.id}/orders`}
        className={({ isActive }) => (isActive ? activeClassName : className)}
      >
        Orders
      </NavLink>
      <button
        type="button"
        className="rounded-md px-3 py-2 text-sm font-medium bg-emerald-500 text-white hover:bg-emerald-700 flex gap-2"
        onClick={() => setReceiveInvoicesIsOpen(true)}
      >
        Receive Invoices
      </button>
      <Modal
        visible={receiveInvoicesIsOpen}
        title="Receive Invoices"
        onClose={() => setReceiveInvoicesIsOpen(false)}
      >
        <ReceiveInvoices />
      </Modal>
    </>
  );
};
