import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { LinkButton, PrimaryButton } from "../../components/buttons";
import { inputClass, inputInvalidClass } from "../../components/form-styles";
import { Field, Row } from "../../components/forms";
import { Page } from "../../components/page";
import { Spinner } from "../../components/spinner";
import { AlertContext } from "../../contexts/alert-context";
import { StoreContext } from "../../contexts/store-context";
import type { StoreCreateInput } from "../../services/stores";
import { StripeService } from "../../services/stripe";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type FormValues = {
  name: string;
  owner_first_name: string;
  owner_last_name: string;
};

// form validation rules
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Store name is required"),

  owner_first_name: Yup.string().required("Owner first name is required"),

  owner_last_name: Yup.string().required("Owner last name is required"),
});

function Add() {
  const { addErrorAlert } = useContext(AlertContext);
  const { user, getAccessTokenSilently } = useAuth0();

  const stripeService = useMemo(
    () => new StripeService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { stores, loading } = useContext(StoreContext);

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      owner_first_name: user?.given_name,
      owner_last_name: user?.family_name,
    },
  });

  function onSubmit(data: FormValues) {
    const store: StoreCreateInput = {
      ...data,
    };
    return createStore(store);
  }

  async function createStore(data: StoreCreateInput) {
    const stripe = await stripePromise;

    return stripeService
      .createCheckoutSession(data)
      .then((response) => {
        stripe?.redirectToCheckout({
          sessionId: response.session_id,
        });
      })
      .catch(addErrorAlert);
  }

  return (
    <Page title="Register a new store with Orderbot">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset()}
        className="flex flex-col gap-2 max-w-xl"
      >
        <div className="h-4" />
        <Row>
          <Field
            label="First Name"
            name="owner_first_name"
            errorMessage={errors.owner_first_name?.message}
          >
            <input
              {...register("owner_first_name")}
              placeholder="Bob"
              className={
                errors.owner_first_name ? inputInvalidClass : inputClass
              }
            />
          </Field>
          <Field
            label="Last Name"
            name="owner_last_name"
            errorMessage={errors.owner_last_name?.message}
          >
            <input
              {...register("owner_last_name")}
              placeholder="Smith"
              className={
                errors.owner_first_name ? inputInvalidClass : inputClass
              }
            />
          </Field>
        </Row>
        <Row>
          <Field
            label="Store Name"
            name="name"
            errorMessage={errors.name?.message}
          >
            <input
              {...register("name")}
              placeholder={
                user?.given_name
                  ? `${user.given_name}'s Wine & Spirits`
                  : "Main Street Wine & Spirits"
              }
              className={errors.name ? inputInvalidClass : inputClass}
            />
          </Field>
        </Row>
        <hr />
        <p className="text-md text-gray-500 pt-4">
          <b>NOTE:</b> At this time we only support stores in AR, OK and NY. If
          your store is located in a different state, please{" "}
          <a href="mailto:hello@liquorstore.tech" className="text-emerald-500">
            get in touch
          </a>{" "}
          to discuss future support.
        </p>
        <p className="text-md text-gray-500 pt-4">
          By clicking subscribe, you agree to our{" "}
          <a
            href="https://liquorstore.tech/terms"
            target="_blank"
            rel="noreferrer"
            className="text-emerald-500"
          >
            terms of service
          </a>{" "}
          and{" "}
          <a
            href="https://liquorstore.tech/privacy"
            target="_blank"
            rel="noreferrer"
            className="text-emerald-500"
          >
            privacy policy
          </a>
          . You will be redirected to Stripe, our payment processor, to complete
          the subscription process. Upon completion, you will receive an email
          with your store's product key & instructions on how to begin uploading
          data from your LiquorPOS installation to our sytems.
        </p>
        <div className="flex gap-2 justify-end pt-4">
          <PrimaryButton
            type="submit"
            disabled={formState.isSubmitting || formState.isSubmitted}
          >
            {(formState.isSubmitting || formState.isSubmitted) && <Spinner />}
            Register
          </PrimaryButton>
          {!loading && stores.length > 0 && (
            <LinkButton to={`/stores/${stores[0].id}`}>Cancel</LinkButton>
          )}
        </div>
      </form>
    </Page>
  );
}

export { Add };
