import * as React from "react";

import type { Store } from "../data/store";
import { useStores } from "../hooks/stores";

type StoreContextType = {
  stores: Store[];
  loading: boolean;
  error: string | undefined;
  updateStore: (store: Store) => Promise<Store>;
};

export const StoreContext = React.createContext<StoreContextType>({
  stores: [],
  loading: true,
  error: undefined,
  updateStore: () => Promise.reject("Not implemented"),
});

export const StoreContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { loading, error, stores, updateStore } = useStores();

  return (
    <StoreContext.Provider
      value={{
        loading,
        error,
        stores,
        updateStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
