interface TableProperties
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  headings?: string[];
}

export const Table: React.FC<TableProperties> = ({
  children,
  headings,
  ...rest
}) => (
  <table className="min-w-full divide-y divide-gray-300" {...rest}>
    <thead>
      <tr>
        {headings?.map((heading, index) => (
          <th
            className="px-1 py-2 text-left text-sm font-semibold text-gray-900"
            key={index}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="bg-white">{children}</tbody>
  </table>
);

type RowProperties = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;

export const Row: React.FC<RowProperties> = ({ children }) => (
  <tr className="even:bg-gray-50 border-b border-transparent hover:border-slate-300">
    {children}
  </tr>
);

type CellProperties = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;

export const Cell: React.FC<CellProperties> = ({ children }) => (
  <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-700">
    {children}
  </td>
);

export default { Table, Row, Cell };
