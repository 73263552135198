import AuthNav from "./auth-nav";
import MainNav from "./main-nav";

const NavBar = () => {
  return (
    <nav className="py-2 px-2 bg-emerald-800 grid grid-flow-col justify-between items-center">
      <MainNav />
      <AuthNav />
    </nav>
  );
};

export default NavBar;
