import * as Yup from "yup";
import { SalesTotalSchema } from "./sales-total";

// form validation rules
export const YearSalesSchema = Yup.object().shape({
  year: Yup.number().required(),
  totals: SalesTotalSchema.required(),
  totals_to_date: SalesTotalSchema.required(),
  totals_to_date_month: SalesTotalSchema.required(),
  monthly: Yup.array().of(SalesTotalSchema).required(),
});

export type YearSales = Yup.InferType<typeof YearSalesSchema>;
