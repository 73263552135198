import type * as React from "react";

interface PageProperties
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string;
}

export const Page: React.FC<PageProperties> = ({
  children,
  title,
  ...properties
}) => {
  return (
    <div className="grid grid-flow-row p-4" {...properties}>
      <div>
        <h2 className="text-bold text-xl text-center">{title}</h2>
      </div>
      <div className="grid justify-center">{children}</div>
    </div>
  );
};
