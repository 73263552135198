/**
 * Element styles for use on forms. These exist here for two reasons:
 * 1) react-form-hook uses refs, so we can't use the shared forms.input
 * 2) for dev fast refresh to work, modules must only export components
 */

// input styles
const inputBaseClass =
  "block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-2";
export const inputClass = `${inputBaseClass} ring-gray-300`;
export const inputInvalidClass = `${inputBaseClass} ring-red-300`;

// checkbox styles
const checkboxBaseClass =
  "h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600";
export const checkboxClass = `${checkboxBaseClass} ring-gray-300`;
export const checkboxInvalidClass = `${checkboxBaseClass} ring-red-300`;

// inputs squared off on the right to allow for action buttons
export const buttonInputBaseClass =
  "block w-full rounded-none rounded-l-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
export const buttonInputClass = `${buttonInputBaseClass} ring-gray-300`;
export const buttonInputInvalidClass = `${buttonInputBaseClass} ring-red-300`;

// button for use on the right side of the above squared input
export const actionButtonBaseClass =
  "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50";
export const actionButtonClass = `${actionButtonBaseClass} ring-gray-300`;
export const actionButtonInvalidClass = `${actionButtonBaseClass} ring-red-300`;
