import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { type Accept, type FileRejection, useDropzone } from "react-dropzone";

import { useAuth0 } from "@auth0/auth0-react";
import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { AlertContext } from "../contexts/alert-context";
import { StoreVendorContext } from "../contexts/store-vendor-context";
import type { Vendor } from "../data/vendor";
import { useSelectedStore } from "../hooks/selected-store";
import { ReceiveInvoicesService } from "../services/receive-invoices";
import { PrimaryButton } from "./buttons";
import { Field, Select } from "./forms";
import { LoadingOverlay } from "./loading";

const useSupportedVendors = () => {
  const { vendors } = useContext(StoreVendorContext);

  return useMemo(() => {
    return vendors.filter((v) => v.invoice_formats.length > 0);
  }, [vendors]);
};

const formatToMimeType = (format: string) => {
  switch (format) {
    case "csv": {
      return "text/csv";
    }
    case "eml": {
      return "application/octet-stream";
    }
    case "pdf": {
      return "application/pdf";
    }
    case "xls": {
      return "application/vnd.ms-excel";
    }
    case "xlsx": {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    case "html": {
      return "text/html";
    }
    default: {
      return "application/octet-stream";
    }
  }
};

/* dropzone expects accepted file formats in this format:
{
  'image/png': ['.png'],
  'text/html': ['.html', '.htm'],
}
*/
const invoiceFormatMimeTypes = (formats: string[]) => {
  return formats.reduce((accumulator: Accept, format) => {
    const mimeType = formatToMimeType(format);
    if (!accumulator[mimeType]) {
      accumulator[mimeType] = [];
    }
    accumulator[mimeType].push(format);

    return accumulator;
  }, {});
};

type FileThumbnailProperties = {
  file: File;
  onRemove: (file: File) => void;
};

const FileThumbnail: React.FC<FileThumbnailProperties> = ({
  file,
  onRemove,
}) => {
  return (
    <div className="border border-gray-200 p-2 rounded text-gray-500 grid grid-flow-col justify-between items-center gap-2">
      <DocumentIcon className="h-5 w-5" aria-hidden="true" />
      <span>{file.name}</span>
      <button
        onClick={() => onRemove(file)}
        title="Remove file"
        className="hover:text-purple-500"
        type="button"
      >
        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
};

const ErrorDisplay = ({
  error,
  onRemove,
}: {
  error: string;
  onRemove: (error: string) => void;
}) => {
  return (
    <div className="border border-red-200 p-2 rounded text-red-400 grid grid-flow-col justify-between items-center gap-2">
      <span>{error}</span>
      <button
        onClick={() => onRemove(error)}
        title="Remove error"
        className="hover:text-purple-500"
        type="button"
      >
        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
};

function isTheSameFile(a: File, b: File) {
  return (
    a.name === b.name && a.size === b.size && a.lastModified === b.lastModified
  );
}

function appendFiles(existing: File[], added: File[]): File[] {
  return added.reduce(
    (accumulator: File[], file: File) => {
      const fileExists = accumulator.some((a) => isTheSameFile(a, file));
      if (!fileExists) {
        accumulator.push(file);
      }
      return accumulator;
    },
    [...existing], // work with a copy of the files so we return a new array
  );
}

const useErrors = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const addErrors = useCallback((newErrors: string[]) => {
    setErrors((errors) => [...errors, ...newErrors]);
  }, []);
  const removeError = useCallback((error: string) => {
    setErrors((errors) =>
      errors.filter((existingError) => existingError !== error),
    );
  }, []);

  return { errors, addErrors, removeError };
};

export const ReceiveInvoices = ({
  vendor: initialVendor,
}: {
  vendor?: Vendor;
}) => {
  const { addErrorAlert } = useContext(AlertContext);
  const { errors, addErrors, removeError } = useErrors();

  const { selectedStore } = useSelectedStore();
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const vendors = useSupportedVendors();
  const [vendor, setVendor] = useState<Vendor | undefined>(initialVendor);
  const [files, setFiles] = useState<File[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new ReceiveInvoicesService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  // update the vendor when updated from the outside
  useEffect(() => {
    if (initialVendor) {
      setVendor(initialVendor);
    }
  }, [initialVendor]);

  const accept = useMemo(() => {
    if (!vendor) {
      return;
    }
    return invoiceFormatMimeTypes(vendor.invoice_formats);
  }, [vendor]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFiles((files) => appendFiles(files, acceptedFiles));
      if (rejectedFiles.length > 0) {
        const errors = rejectedFiles.map(
          (rejectedFile) =>
            `File ${rejectedFile.file.name} is not a supported format for vendor ${vendor?.name}.`,
        );
        addErrors(errors);
      }
    },
    [vendor, addErrors],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  const onVendorChange = useCallback(
    (error: React.ChangeEvent<HTMLSelectElement>) => {
      if (files.length > 0) {
        const response = confirm(
          "Are you sure you want to change vendors? All files will be removed.",
        );
        if (!response) {
          return;
        }
      }

      const vendorId = error.target.value;
      const vendor = vendors.find((v) => v.vendor_id === vendorId);
      setVendor(vendor);
      setFiles([]);
    },
    [vendors, files],
  );

  const onFileRemove = useCallback((file: File) => {
    setFiles((files) => files.filter((f) => f !== file));
  }, []);

  const submit = useCallback(() => {
    if (!vendor || !selectedStore) {
      console.warn("Vendor or store not selected");
      return;
    }
    setLoading(true);
    service
      .receiveInvoices({
        storeId: selectedStore.id,
        vendorId: vendor.vendor_id,
        invoices: files,
      })
      .then(() => {
        setLoading(false);
        setSucceeded(true);
      })
      .catch(() => {
        setLoading(false);
        addErrorAlert("Failed to import invoices. Please try again.");
      });
  }, [files, vendor, service, selectedStore, addErrorAlert]);

  if (succeeded) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="text-gray-500">
          Your receiving files are on their way. You should receive an email
          shortly with your converted invoices for receiving.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-flow-row gap-2 w-96">
        <Field label="Vendor" name="vendor">
          <Select value={vendor?.vendor_id} onChange={onVendorChange}>
            <option value="">Select a vendor</option>
            {vendors.map((v) => (
              <option key={v.vendor_id} value={v.vendor_id}>
                {v.name}
              </option>
            ))}
          </Select>
        </Field>
        {vendor && (
          <Field label="Files" name="files">
            {errors.length > 0 && (
              <div className="grid grid-flow-row gap-1 w-full overflow-hidden">
                {errors.map((error, index) => (
                  <ErrorDisplay
                    key={index}
                    error={error}
                    onRemove={removeError}
                  />
                ))}
              </div>
            )}
            <div className="grid grid-flow-row gap-2">
              <div
                className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${
                  isDragActive ? "bg-gray-100" : "bg-white"
                }`}
                {...getRootProps()}
              >
                <div className="text-center">
                  <ArrowUpTrayIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input {...getInputProps()} disabled={!vendor} />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    Supported formats: {vendor.invoice_formats.join(", ")}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-1 justify-start items-center">
                {files.map((file, index) => (
                  <FileThumbnail
                    file={file}
                    key={index}
                    onRemove={onFileRemove}
                  />
                ))}
              </div>
            </div>
          </Field>
        )}
        <div className="flex justify-end">
          <PrimaryButton
            disabled={!vendor || files.length === 0}
            onClick={submit}
          >
            Import
          </PrimaryButton>
        </div>
      </div>
      {loading && <LoadingOverlay />}
    </>
  );
};
