import * as Yup from "yup";
import { ProductInfoSchema } from "./product-info";

// form validation rules
export const OrderItemSchema = Yup.object().shape({
  product: ProductInfoSchema.required(),
  suggested_cases: Yup.number().moreThan(-1).integer(),
  cases: Yup.number().moreThan(-1).integer(),
  suggested_units: Yup.number().moreThan(-1).integer(),
  units: Yup.number().moreThan(-1).integer(),
});

export type OrderItem = Yup.InferType<typeof OrderItemSchema>;

export function units(orderItem: OrderItem) {
  if (orderItem.units !== undefined) {
    return orderItem.units;
  }
  if (orderItem.suggested_units !== undefined) {
    return orderItem.suggested_units;
  }
  return 0;
}

export function cases(orderItem: OrderItem) {
  if (orderItem.cases !== undefined) {
    return orderItem.cases;
  }
  if (orderItem.suggested_cases !== undefined) {
    return orderItem.suggested_cases;
  }
  return 0;
}

export function totalUnits(orderItem: OrderItem) {
  return cases(orderItem) * orderItem.product.case_qty + units(orderItem);
}

export function totalCost(orderItem: OrderItem) {
  return totalUnits(orderItem) * orderItem.product.last_cost;
}
