type DisplayQuantityToggleProperties = {
  displayInCases: boolean;
  setDisplayInCases: (displayInCases: boolean) => void;
};

export const DisplayQuantityToggle: React.FC<
  DisplayQuantityToggleProperties
> = ({ displayInCases, setDisplayInCases }) => {
  return (
    <fieldset className="inline-flex rounded border border-inset border-gray-300">
      <button
        type="button"
        className={`px-2 py-2 text-sm hover:bg-emerald-500 hover:text-white ${!displayInCases ? "bg-emerald-500 text-white" : "text-gray-600 bg-white"}`}
        onClick={() => setDisplayInCases(false)}
      >
        Units
      </button>
      <button
        type="button"
        className={`px-2 py-2 text-sm hover:bg-emerald-500 hover:text-white ${displayInCases ? "bg-emerald-500 text-white" : "text-gray-600 bg-white"}`}
        onClick={() => setDisplayInCases(true)}
      >
        Cases
      </button>
    </fieldset>
  );
};
