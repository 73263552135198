import {
  ArrowPathIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useEffect, useState } from "react";

function useUpdateChecker() {
  const [isOutdated, setIsOutdated] = useState(false);
  // check for update every 5 minutes
  const checkVersion = useCallback(() => {
    // skip version check in development
    if (import.meta.env.MODE !== "production") {
      return;
    }
    fetch("/assets/VERSION", { cache: "no-store" })
      .then((response) => response.text())
      .then((version) => {
        const trimmedVersion = version.trim();
        if (import.meta.env.VITE_APP_VERSION !== trimmedVersion) {
          setIsOutdated(true);
        }
      });
  }, []);

  // initial version check
  useEffect(checkVersion);

  // check for update every 5 minutes
  useEffect(() => {
    const intervalId = setInterval(checkVersion, 300000); // 5 * 60 * 1000
    return () => clearInterval(intervalId);
  });
  return isOutdated;
}

// reload doesn't work unless in a setTimeout
// https://stackoverflow.com/questions/18967532/window-location-reload-not-working-for-firefox-and-chrome
function update() {
  setTimeout(() => window.location.reload(), 100);
}

export function Updater() {
  const isOutdated = useUpdateChecker();

  if (isOutdated)
    return (
      <div className="rounded-md bg-blue-50 p-4 flex gap-2 items-center fixed top-0 right-0 m-2">
        <InformationCircleIcon
          aria-hidden="true"
          className="h-5 w-5 text-blue-400"
        />
        <p className="text-sm text-blue-700">
          A new software update is available. Please refresh the page to get the
          latest version.
        </p>
        <button
          className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600 flex items-center gap-2 hover:bg-blue-100 px-2 py-1 rounded-md"
          type="button"
          onClick={update}
        >
          Update
          <ArrowPathIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
        </button>
      </div>
    );
}
