import type { Auth0ContextInterface } from "@auth0/auth0-react";
import * as Yup from "yup";

import { FetchWrapper, createSingleHandler } from "../helpers/fetch-wrapper";
import type { StoreCreateInput } from "./stores";

const baseUrl = `${import.meta.env.VITE_SERVER_URL}/payments`;

export class StripeService {
  fetchWrapper: FetchWrapper;

  constructor(tokenAccessor: Auth0ContextInterface["getAccessTokenSilently"]) {
    this.fetchWrapper = new FetchWrapper(tokenAccessor);
  }

  createCheckoutSession(parameters: StoreCreateInput) {
    return this.fetchWrapper
      .post(`${baseUrl}/checkout-session`, parameters)
      .then(createSingleHandler(CreateCheckoutSessionResultSchema));
  }

  createPortalSession(storeId: string) {
    return this.fetchWrapper
      .post(`${baseUrl}/${storeId}/portal-session`, {})
      .then(createSingleHandler(CreatePortalSessionResultSchema));
  }
}

const CreateCheckoutSessionResultSchema = Yup.object().shape({
  session_id: Yup.string().required(),
});

export type CreateCheckoutSessionResult = Yup.InferType<
  typeof CreateCheckoutSessionResultSchema
>;

const CreatePortalSessionResultSchema = Yup.object().shape({
  portal_url: Yup.string().required(),
});

export type CreatePortalSessionResultSchema = Yup.InferType<
  typeof CreateCheckoutSessionResultSchema
>;
