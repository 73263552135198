import * as React from "react";

import type { Product } from "../data/product";
import { useProducts } from "../hooks/products";

type StoreProductContext = {
  products: Product[];
  loading: boolean;
  saving: boolean;
  error: string | undefined;
  updateProduct: (vendor: Product) => Promise<Product>;
};

export const StoreProductContext = React.createContext<StoreProductContext>({
  products: [],
  loading: true,
  saving: false,
  error: undefined,
  updateProduct: () => Promise.reject("Not implemented"),
});

export const StoreProductContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { loading, saving, error, products, updateProduct } = useProducts();

  return (
    <StoreProductContext.Provider
      value={{
        loading,
        saving,
        error,
        products,
        updateProduct,
      }}
    >
      {children}
    </StoreProductContext.Provider>
  );
};
