import { Component, type ErrorInfo, type ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  errorMessage?: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    errorMessage: undefined,
  };

  public static getDerivedStateFromError(error: Error | string): State {
    // Update state so the next render will show the fallback UI.
    const errorMessage = typeof error === "string" ? error : error.message;
    return { errorMessage };
  }

  public componentDidCatch(
    error: Error | string,
    errorInfo: ErrorInfo | string,
  ) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.errorMessage) {
      return (
        <div className="grid justify-center align-center h-full">
          <div className="grid content-center justify-items-center">
            <h1 className="font-bold text-xl text-red-700">
              We're sorry, there's been an error
            </h1>
            <div>{String(this.state.errorMessage)}</div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
