import { useAuth0 } from "@auth0/auth0-react";
import { useSelectedStore } from "../../hooks/selected-store";
import { Logo } from "../logo";
import { AppNav } from "./app-nav";
import { LandingNav } from "./landing-nav";

export const className =
  "rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-emerald-700 hover:text-white";
export const activeClassName =
  "rounded-md bg-emerald-900 px-3 py-2 text-sm font-medium text-white";

const MainNav = () => {
  const { isAuthenticated } = useAuth0();
  const { selectedStore } = useSelectedStore();

  return (
    <div className="grid grid-flow-col items-center justify-start gap-2">
      <div className="flex items-center mr-2">
        <Logo />
        <span className="ml-2 text-xl font-bold text-white">Orderbot</span>
      </div>
      {isAuthenticated && selectedStore && <AppNav store={selectedStore} />}
      {!isAuthenticated && <LandingNav />}
    </div>
  );
};

export default MainNav;
