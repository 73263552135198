import { format } from "date-fns/format";
import { enUS } from "date-fns/locale/en-US";
import { useMemo } from "react";
import type { Store } from "../../data/store";

export const LastImport = ({ store }: { store: Store }) => {
  const lastImported = useMemo<string>(() => {
    return format(store.updated_at, "Pp", { locale: enUS });
  }, [store]);

  return (
    <span className="text-sm font-medium tracking-tight text-gray-500 justify-self-end">
      Last refreshed on {lastImported}
    </span>
  );
};
