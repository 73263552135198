import * as Yup from "yup";
import { fixGoDate } from "../helpers/fix-date";
import { OrderItemSchema } from "./order-item";
import { OrderOptionsSchema } from "./order-options";

export enum OrderStatus {
  Draft = "draft",
  Sending = "sending",
  Sent = "sent",
  Canceled = "canceled",
  Failed = "failed",
}

// form validation rules
export const OrderSchema = Yup.object().shape({
  store_id: Yup.string().required(),
  id: Yup.string().required(),
  vendor_id: Yup.string().required(),

  options: OrderOptionsSchema.required(),
  items: Yup.array().of(OrderItemSchema.required()).required(),
  status: Yup.string().oneOf(Object.values(OrderStatus)).required(),

  created_at: Yup.date().required(),
  updated_at: Yup.date().required(),
  submitted_at: Yup.date().nullable().transform(fixGoDate),
});

export type Order = Yup.InferType<typeof OrderSchema>;

// form validation rules
export const CreateOrderSchema = Yup.object().shape({
  vendor_id: Yup.string().required(),
  options: OrderOptionsSchema.optional(),
  items: Yup.array().of(OrderItemSchema.required()),
});

export type CreateOrder = Yup.InferType<typeof CreateOrderSchema>;
