import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";

import { StoreContext } from "../../contexts/store-context";
import { useSelectedStore } from "../../hooks/selected-store";
import LoginButton from "../login-button";
import LogoutButton from "../logout-button";
import { SignupButton } from "../signup-button";
import { Spinner } from "../spinner";
import { StoreMenu } from "./store-menu";

const AuthNav = () => {
  const { isAuthenticated } = useAuth0();
  const { loading } = useContext(StoreContext);
  const { selectedStore } = useSelectedStore();

  if (!isAuthenticated) {
    return (
      <div className="grid grid-flow-col items-center justify-end gap-2">
        <LoginButton />
        <SignupButton
          className="py-2 px-4 bg-white text-emerald-600 hover:bg-emerald-50"
          label="Sign Up"
        />
      </div>
    );
  }
  if (selectedStore) {
    return <StoreMenu />;
  }
  if (loading) {
    return <Spinner />;
  }
  return <LogoutButton />;
};

export default AuthNav;
