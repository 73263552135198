import * as Yup from "yup";

// form validation rules
export const AddressSchema = Yup.object().shape({
  line_one: Yup.string(),
  line_two: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string(),
  country: Yup.string(),
});

export type Address = Yup.InferType<typeof AddressSchema>;
