import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { ProductTableContext } from "../../contexts/product-table-context";
import {
  type Product,
  type SaleStatDays,
  saleStatDays,
} from "../../data/product";
import { preciseCurrencyFormatter } from "../../helpers/format-currency";
import { useHijackSearch } from "../../helpers/hijack-search";
import {
  type StringOption,
  stringOptionsToValues,
  useStringOptions,
} from "../../helpers/string-options";
import { Checkbox, MultiSelect, Select } from "../forms";
import { DisplayQuantityToggle } from "../quantity-toggle";
import { SearchInput } from "../search-input";

const useSearchFocus = () => {
  const searchReference = useRef<HTMLInputElement>(null);

  useHijackSearch(() => {
    searchReference.current?.focus();
  });

  return searchReference;
};

export function SearchField() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { query } = filters;
  const searchReference = useSearchFocus();
  return (
    <SearchInput
      placeholder="Filter..."
      value={query}
      onChange={(event) =>
        setFilters({ ...filters, query: event.target.value })
      }
      inputRef={searchReference}
    />
  );
}

export function DepartmentFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { departments } = filters;

  const options: StringOption[] = useMemo(() => {
    const departments = new Set<string>();
    for (const product of products) {
      departments.add(product.department);
    }

    //convert set to array, remove blank items
    return [...departments]
      .filter((department) => department.trim() !== "")
      .sort()
      .map((department) => ({ value: department, label: department }));
  }, [products]);

  const value = useStringOptions(departments);

  const onChange = useCallback(
    (departmentOptions: readonly StringOption[]) => {
      const departments = stringOptionsToValues(departmentOptions);
      setFilters({ ...filters, departments });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Department">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

export function VendorNameFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { vendorNames } = filters;

  const allVendorNames = useMemo(() => {
    const vendorNames = new Set<string>();
    for (const product of products) {
      vendorNames.add(product.vendor_name);
    }

    //convert set to array, drop blank items
    return [...vendorNames]
      .filter((vendorName) => vendorName.trim() !== "")
      .sort();
  }, [products]);

  const options = useStringOptions(allVendorNames);
  const value = useStringOptions(vendorNames);

  const onChange = useCallback(
    (vendorNameOptions: readonly StringOption[]) => {
      const vendorNames = stringOptionsToValues(vendorNameOptions);
      setFilters({ ...filters, vendorNames });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Vendor">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

export function SoldInFilter({
  overrideSoldInDays,
}: {
  overrideSoldInDays?: SaleStatDays[];
}) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { soldIn } = filters;

  const soldInDays = useMemo(() => {
    if (overrideSoldInDays) {
      return overrideSoldInDays;
    }
    return [...saleStatDays];
  }, [overrideSoldInDays]);

  return (
    <FilterWrapper label="Sold In Last">
      <Select
        value={soldIn}
        onChange={(event) =>
          setFilters({
            ...filters,
            soldIn: Number(event.target.value) as SaleStatDays,
          })
        }
      >
        {soldInDays.map((option, index) => (
          <option key={index} value={option}>
            {option} days
          </option>
        ))}
      </Select>
    </FilterWrapper>
  );
}

export function NotSoldInFilter({
  overrideSoldInDays,
}: {
  overrideSoldInDays?: SaleStatDays[];
}) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { notSoldIn, soldIn } = filters;

  const soldInDays = useMemo(() => {
    const options = overrideSoldInDays || [...saleStatDays];
    // when soldIn is set, ensure notSoldIn is less
    return options
      .filter((option) => soldIn === undefined || option < soldIn)
      .sort((a, b) => b - a);
  }, [overrideSoldInDays, soldIn]);

  // if the user changes the soldIn filter so that the current notSoldIn filter is no longer available,
  // reset the notSoldIn filter to the first available option
  useEffect(() => {
    if (notSoldIn && !soldInDays.includes(notSoldIn)) {
      setFilters({ ...filters, notSoldIn: soldInDays[0] });
    }
  }, [soldInDays, notSoldIn, setFilters, filters]);

  return (
    <FilterWrapper label="Not Sold In Last">
      <Select
        value={notSoldIn}
        onChange={(event) =>
          setFilters({
            ...filters,
            notSoldIn: Number(event.target.value) as SaleStatDays,
          })
        }
      >
        {soldInDays.map((option, index) => (
          <option key={index} value={option}>
            {option} days
          </option>
        ))}
      </Select>
    </FilterWrapper>
  );
}

export function TypeFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { types } = filters;

  const allTypes = useMemo(() => {
    const types = new Set<string>();
    for (const product of products) {
      types.add(product.type);
    }

    //convert set to array
    return [...types].filter((type) => type.trim() !== "").sort();
  }, [products]);
  const options = useStringOptions(allTypes);

  const value = useStringOptions(types);
  const onChange = useCallback(
    (typeOptions: readonly StringOption[]) => {
      const types = stringOptionsToValues(typeOptions);
      setFilters({ ...filters, types });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Type">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

function FilterWrapper({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`flex flex-row gap-2 items-center ${className || ""}`}>
      <div className="text-gray-600">{label}</div>
      {children}
    </div>
  );
}

export function HideDisabledFilter() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { hideDisabled } = filters;

  return (
    <FilterWrapper label="Hide Disabled">
      <Checkbox
        invalid={false}
        checked={hideDisabled === true}
        onChange={(event) => {
          setFilters({ ...filters, hideDisabled: event.target.checked });
        }}
      />
    </FilterWrapper>
  );
}

export function TotalValue({ className }: { className?: string }) {
  const { filteredProducts } = useContext(ProductTableContext);
  const totalValue = useMemo(() => {
    const value = filteredProducts.reduce((total, product) => {
      return total + product.last_cost * product.inventory_qty;
    }, 0);
    return preciseCurrencyFormatter(value);
  }, [filteredProducts]);

  return (
    <FilterWrapper label="Total Value" className={className}>
      {totalValue}
    </FilterWrapper>
  );
}

export function DisplayInCasesToggle() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { displayInCases } = filters;

  return (
    <DisplayQuantityToggle
      displayInCases={displayInCases || false}
      setDisplayInCases={(displayInCases) =>
        setFilters({ ...filters, displayInCases })
      }
    />
  );
}
