import { formatDistance } from "date-fns/formatDistance";
import { enUS } from "date-fns/locale/en-US";

const formatDistanceLocale = {
  lessThanXSeconds: "<{{count}}s",
  xSeconds: " {{count}}s",
  halfAMinute: " 30s",
  lessThanXMinutes: "<{{count}}min",
  xMinutes: " {{count}}min",
  aboutXHours: "~{{count}}h",
  xHours: " {{count}}h",
  xDays: " {{count}}d",
  aboutXWeeks: "~{{count}}w",
  xWeeks: " {{count}}w",
  aboutXMonths: "~{{count}}mo",
  xMonths: " {{count}}mo",
  aboutXYears: "~{{count}}y",
  xYears: " {{count}}y",
  overXYears: ">{{count}}y",
  almostXYears: "~{{count}}y",
};

const formatDistanceShort = (
  token: keyof typeof formatDistanceLocale,
  count: number,
) => {
  const result = (formatDistanceLocale[token] as string).replace(
    "{{count}}",
    `${count}`,
  );

  return result;
};

export const formatDate = (date: Date, today: Date) => {
  return formatDistance(date, today, {
    locale: {
      ...enUS,
      formatDistance: formatDistanceShort,
    },
  });
};
