import type { FC } from "react";

type NoResultsPropertiess = {
  label: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const NoResults: FC<NoResultsPropertiess> = ({ label, children }) => {
  return (
    <div className="h-full w-full grid grid-flow-row content-center place-items-center gap-3">
      <span className="text-xl text-gray-400">{label}</span>
      {children}
    </div>
  );
};
