import { useAuth0 } from "@auth0/auth0-react";
import { DangerButton } from "./buttons";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <DangerButton
      onClick={() =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      }
    >
      Log Out
    </DangerButton>
  );
};

export default LogoutButton;
