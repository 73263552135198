import * as Yup from "yup";
import { RepresentativeSchema } from "./representative";

export const salesPeriodDays = [30, 60, 90, 180, 365] as const;
export type SalesPeriodDays = (typeof salesPeriodDays)[number];

export const qohMinPercents = [
  25, 30, 50, 75, 100, 125, 150, 175, 200,
] as const;
export type QOHMinPercent = (typeof qohMinPercents)[number];

export const reorderWeeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
export type ReorderWeeks = (typeof reorderWeeks)[number];

// form validation rules
export const OrderDefaultsSchema = Yup.object().shape({
  qoh_threshold_percent: Yup.mixed<QOHMinPercent>()
    .oneOf(qohMinPercents)
    .defined()
    .required(),
  sales_period_days: Yup.mixed<SalesPeriodDays>()
    .oneOf(salesPeriodDays)
    .defined()
    .required(),
  reorder_weeks: Yup.mixed<ReorderWeeks>()
    .oneOf(reorderWeeks)
    .defined()
    .required(),
  fudge_percent: Yup.number().defined(),

  send_method: Yup.string().defined(),
});

export const OrderSettingsSchema = Yup.object().shape({
  defaults: OrderDefaultsSchema.required(),

  send_methods: Yup.array().of(Yup.string().required()).required(),
  representatives: Yup.array().of(RepresentativeSchema.required()).required(),
  store_emails: Yup.array().of(Yup.string().email().required()).defined(),
});

export type OrderSettings = Yup.InferType<typeof OrderSettingsSchema>;
