import { useMemo } from "react";

export type StringOption = { value: string; label: string };

export function stringOptionsToValues(stringOptions: readonly StringOption[]) {
  return stringOptions.map((stringOption) => stringOption.value);
}

export function useStringOptions(strings?: string[]): StringOption[] {
  return useMemo(() => {
    return (
      strings
        ?.filter((string) => string.trim() !== "")
        .map((string) => ({ value: string, label: string })) || []
    );
  }, [strings]);
}
