import { type FC, useMemo } from "react";
import type { Store } from "../../data/store";
import { WidgetContainer } from "./widget-container";

import { BarChart } from "@mui/x-charts/BarChart";
import type { SalesTotal } from "../../data/sales-total";

import { shortCurrencyFormatter } from "../../helpers/format-currency";
import {
  MarginSummary,
  SalesSummary,
  TransactionsSummary,
} from "./sales-summaries";
import {
  lastYearColor,
  thisYearColor,
  thisYearEstimatesColor,
} from "./sales-widget-constants";

const displayTypes = ["Sales", "Transactions", "Gross Margin"] as const;
type DisplayType = (typeof displayTypes)[number];

type SalesDisplayProperties = {
  store: Store;
  displayType: DisplayType;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const createSalesData = (totals: SalesTotal[], displayType: DisplayType) => {
  return totals.map((total) => {
    switch (displayType) {
      case "Sales": {
        return total.sales;
      }
      case "Transactions": {
        return total.transactions;
      }
      case "Gross Margin": {
        return total.sales - total.costs;
      }
    }
  });
};

export const StoreSalesStatsDisplay: FC<SalesDisplayProperties> = ({
  store,
  displayType,
}) => {
  const { lastYearSales, thisYearSales } = useMemo(() => {
    const lastYearSales = store.sales_stats.historical.at(-2);
    const thisYearSales = store.sales_stats.historical.at(-1);
    if (!thisYearSales) {
      throw "Missing this year sales data.";
    }
    return { lastYearSales, thisYearSales };
  }, [store]);

  const series = useMemo(() => {
    const lastYearData =
      lastYearSales && createSalesData(lastYearSales.monthly, displayType);
    const thisYearData = createSalesData(thisYearSales.monthly, displayType);
    const thisYearEstimatesData = createSalesData(
      store.sales_stats.current_year_estimates.monthly,
      displayType,
    );
    const valueFormatter = (value: number | null) => {
      if (!value) return "N/A";

      if (displayType === "Transactions") {
        return `${value.toFixed(0)}`;
      }

      return shortCurrencyFormatter(value);
    };

    const lastYearSeries =
      (lastYearSales && [
        {
          label: `${lastYearSales?.year}`,
          data: lastYearData,
          id: "lastYear",
          stack: "stack1",
          color: lastYearColor,
          valueFormatter,
        },
      ]) ||
      [];
    return [
      ...lastYearSeries,
      {
        label: `${thisYearSales?.year}`,
        data: thisYearData,
        id: "thisYear",
        stack: "stack2",
        color: thisYearColor,
        valueFormatter,
      },
      {
        label: `${thisYearSales?.year} Est.`,
        data: thisYearEstimatesData,
        id: "thisYearEstimates",
        stack: "stack3",
        color: thisYearEstimatesColor,
        valueFormatter,
      },
    ];
  }, [store, lastYearSales, thisYearSales, displayType]);

  return (
    <WidgetContainer title={displayType}>
      {displayType === "Gross Margin" && (
        <MarginSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={lastYearSales}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      {displayType === "Sales" && (
        <SalesSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={lastYearSales}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      {displayType === "Transactions" && (
        <TransactionsSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={lastYearSales}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      <BarChart
        width={500}
        height={300}
        series={series}
        xAxis={[{ data: months, scaleType: "band" }]}
        margin={{ left: 60, right: 0, top: 50, bottom: 20 }}
      />
    </WidgetContainer>
  );
};
