import { yupResolver } from "@hookform/resolvers/yup";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import {
  checkboxClass,
  checkboxInvalidClass,
  inputClass,
  inputInvalidClass,
} from "../../components/form-styles";
import { Field } from "../../components/forms";
import { Spinner } from "../../components/spinner";
import { AlertContext } from "../../contexts/alert-context";
import { StoreProductContext } from "../../contexts/store-product-context";
import type { Product } from "../../data/product";

type FormValues = {
  disabled: boolean;
  min_qoh: number;
  notes: string;
};

// form validation rules
const ValidationSchema = Yup.object().shape({
  disabled: Yup.boolean().required("Disabled is required"),
  min_qoh: Yup.number().required("Minimum QOH is required"),
  notes: Yup.string().defined(),
});

export function EditProduct({
  product,
  onClose,
}: { product: Product; onClose: () => void }) {
  const { addSuccessAlert } = useContext(AlertContext);
  const { updateProduct } = useContext(StoreProductContext);

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm<FormValues>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      ...product,
    },
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      const updatedProduct = {
        ...product,
        ...data,
      };

      updateProduct(updatedProduct).then(() => {
        addSuccessAlert(
          `${updatedProduct.brand} | ${updatedProduct.description} updated`,
          { keepAfterRouteChange: true, autoCloseAfterTimeMS: 15_000 },
        );
        onClose();
      });
    },
    [updateProduct, product, onClose, addSuccessAlert],
  );

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset()}
        className="flex flex-col gap-2"
      >
        <Field
          label="Minimum QOH"
          name="min_qoh"
          errorMessage={errors.min_qoh?.message}
        >
          <input
            {...register("min_qoh", { valueAsNumber: true })}
            type="number"
            className={errors.min_qoh ? inputInvalidClass : inputClass}
            min="0"
          />
        </Field>

        <Field
          label={`Disabled${product.disabled && product.disabled_toggled_at ? ` (since ${formatDistanceToNowStrict(product.disabled_toggled_at, { addSuffix: true })})` : ""}`}
          name="disabled"
          errorMessage={errors.disabled?.message}
        >
          <input
            {...register("disabled")}
            type="checkbox"
            className={errors.disabled ? checkboxInvalidClass : checkboxClass}
          />
        </Field>

        <Field label="Notes" name="notes" errorMessage={errors.notes?.message}>
          <textarea
            {...register("notes")}
            className={errors.notes ? inputInvalidClass : inputClass}
          />
        </Field>

        <div className="flex gap-2 justify-center pt-4">
          <PrimaryButton type="submit" disabled={formState.isSubmitting}>
            {formState.isSubmitting && <Spinner />}
            Update
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </div>
      </form>
    </div>
  );
}
