import type { Auth0ContextInterface } from "@auth0/auth0-react";
import Base64 from "crypto-js/enc-base64";
import WordArray from "crypto-js/lib-typedarrays";
import MD5 from "crypto-js/md5";
import * as Yup from "yup";
import { FetchWrapper, createSingleHandler } from "../helpers/fetch-wrapper";

type ImportInvoicesProperties = {
  invoices: File[];
  vendorId: string;
  storeId: string;
};

type md5Signature = {
  filename: string;
  md5: string;
};

type SignFilesProperties = {
  vendorId: string;
  storeId: string;
  md5s: md5Signature[];
};

type ProcessFilesProperties = {
  vendorId: string;
  prefix: string;
  storeId: string;
};

const SignResultSchema = Yup.object().shape({
  prefix: Yup.string().required(),
  urls: Yup.mixed().required(),
});

type SignResult = Yup.InferType<typeof SignResultSchema>;

export class ReceiveInvoicesService {
  private fetchWrapper: FetchWrapper;
  private baseURL = "https://api.liquorstore.tech";

  constructor(tokenAccessor: Auth0ContextInterface["getAccessTokenSilently"]) {
    this.fetchWrapper = new FetchWrapper(tokenAccessor);
  }

  async receiveInvoices({
    invoices,
    vendorId,
    storeId,
  }: ImportInvoicesProperties) {
    // create md5s for files
    const md5s = await Promise.all(invoices.map(this.md5Base64));
    // submit md5's to server for signed S3 URLs
    const signResult = await this.signFiles({
      storeId,
      vendorId: vendorId,
      md5s,
    });
    // upload files to specified URLs
    await this.uploadFiles(invoices, md5s, signResult);
    // tell server to process uploaded files
    return this.processFiles({ vendorId, prefix: signResult.prefix, storeId });
  }

  private async md5Base64(file: File): Promise<md5Signature> {
    // converting the arrayBuffer to a number array creates an invalid md5 hash. annoyingly forcing this type works
    const content = (await file.arrayBuffer()) as unknown as number[];

    return {
      filename: file.name,
      md5: MD5(WordArray.create(content)).toString(Base64),
    };
  }

  private async signFiles({ storeId, vendorId, md5s }: SignFilesProperties) {
    return this.fetchWrapper
      .post(`${this.baseURL}/stores/${storeId}/generate-invoices-upload-urls`, {
        vendor_id: vendorId,
        invoices: md5s,
      })
      .then(createSingleHandler(SignResultSchema));
  }

  private async uploadFiles(
    files: File[],
    md5s: md5Signature[],
    signResult: SignResult,
  ) {
    const urls = new Map(Object.entries(signResult.urls)) as Map<
      string,
      string
    >;
    const promises = files.map((file, index) => {
      const md5 = md5s[index].md5;
      const url = urls.get(md5);
      if (url === undefined) {
        throw "No URL found for file";
      }
      // not using fetchWrapper, as we don't use auth headers for request to different upload URL
      return fetch(url, {
        method: "PUT",
        headers: new Headers({ "Content-MD5": md5 }),
        body: file,
      });
    });
    return Promise.all(promises).then((responses) => {
      const errors = responses
        .filter((response) => !response.ok)
        .map((response) => response.statusText);
      if (errors.length > 0) {
        throw new Error(errors.join(", "));
      }
    });
  }

  private async processFiles({
    vendorId,
    prefix,
    storeId,
  }: ProcessFilesProperties) {
    const data = {
      vendor_id: vendorId,
      prefix,
    };
    return this.fetchWrapper.post(
      `${this.baseURL}/stores/${storeId}/receive-invoices`,
      data,
    );
  }
}
