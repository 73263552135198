import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns/format";
import { Fragment, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../../components/loading";
import { NoResults } from "../../components/no-results";
import { Toolbar } from "../../components/toolbar";
import { StoreVendorContext } from "../../contexts/store-vendor-context";
import type { Order } from "../../data/order";
import type { Store } from "../../data/store";
import type { Vendor } from "../../data/vendor";
import { useOrders } from "../../hooks/orders";
import { useSelectedStore } from "../../hooks/selected-store";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function NewOrderMenu({
  selectedStore,
  vendors,
}: {
  selectedStore: Store;
  vendors: Vendor[];
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          New Order
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {vendors.map((vendor) => (
              <Menu.Item key={vendor.vendor_id}>
                {({ active }) => (
                  <Link
                    to={`/stores/${selectedStore.id}/orders/new?vendorId=${vendor.vendor_id}`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm",
                    )}
                  >
                    {vendor.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const VendorName = ({ id, vendors }: { id: string; vendors: Vendor[] }) => {
  const vendor = useMemo(() => {
    return vendors.find((v) => v.vendor_id === id);
  }, [id, vendors]);

  return vendor?.name;
};

function OrderCard({
  store,
  vendors,
  order,
  route,
}: {
  store: Store;
  vendors: Vendor[];
  order: Order;
  route: string;
}) {
  return (
    <li>
      <Link
        to={`/stores/${store.id}/orders/${order.id}${route}`}
        className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 p-5 sm:flex-nowrap hover:bg-gray-50"
      >
        <div>
          <p className="text-sm font-semibold leading-6 text-gray-900">
            <VendorName id={order.vendor_id} vendors={vendors} />
          </p>
          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p>Last Updated {format(order.updated_at, "MM/dd/yyyy hh:mmaa")}</p>
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-x-4">
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">{order.status}</p>
          </div>
          <ChevronRightIcon
            className="h-5 w-5 flex-none text-gray-400"
            aria-hidden="true"
          />
        </div>
      </Link>
    </li>
  );
}

function OrderList({
  title,
  store,
  vendors,
  orders,
  route,
}: {
  title: string;
  store: Store;
  vendors: Vendor[];
  orders: Order[];
  route: string;
}) {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 p-5">{title}</h1>
      <ul className="divide-y divide-gray-100">
        {orders.map((order) => (
          <OrderCard
            key={order.id}
            store={store}
            vendors={vendors}
            order={order}
            route={route}
          />
        ))}
        {orders.length === 0 && <NoResults label="No matching orders" />}
      </ul>
    </>
  );
}

export function Orders() {
  const { selectedStore } = useSelectedStore();
  const { loading: ordersLoading, orders } = useOrders();
  const { vendors, loading: vendorsLoading } = useContext(StoreVendorContext);
  const enabledVendors = useMemo(() => {
    return vendors.filter((v) => !v.disabled);
  }, [vendors]);

  if (ordersLoading || vendorsLoading || !selectedStore) {
    return <Loading />;
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Toolbar layout="justify-end">
        <NewOrderMenu selectedStore={selectedStore} vendors={enabledVendors} />
      </Toolbar>
      <div className="w-[700px] mx-auto">
        <OrderList
          title="Open Orders"
          store={selectedStore}
          vendors={vendors}
          orders={orders.filter((order) => order.status === "draft")}
          route="/edit"
        />
        <OrderList
          title="History"
          store={selectedStore}
          vendors={vendors}
          orders={orders.filter((order) => order.status !== "draft")}
          route="/summary"
        />
      </div>
    </div>
  );
}
