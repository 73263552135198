import { useAuth0 } from "@auth0/auth0-react";

export const SignupButton: React.FC<{ className: string; label: string }> = ({
  className,
  label,
}) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      className={`rounded transition duration-150 ${className}`}
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
          },
        })
      }
    >
      {label}
    </button>
  );
};
