import type { ColumnDefinition } from "./column-definition";

export type ColumnSort<T> = {
  column: ColumnDefinition<T>;
  direction: "asc" | "desc";
};

export function multiSort<T>(sorts: ColumnSort<T>[]) {
  return (a: T, b: T) => {
    for (const { column, direction } of sorts) {
      let comparison: number;

      if (column.compareFunction) {
        comparison = column.compareFunction(a, b);
      } else {
        const aData = column.toString(a);
        const bData = column.toString(b);

        comparison = aData.localeCompare(bData);
      }

      if (comparison === 0) {
        continue;
      }

      return direction === "asc" ? comparison : -comparison;
    }

    return 0;
  };
}
