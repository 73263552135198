import { PrinterIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { PrimaryButton } from "../../components/buttons";
import { PrintContainer } from "../../components/print-container";
import { PrintTable } from "../../components/printable-table";
import {
  DepartmentFilter,
  HideDisabledFilter,
  NotSoldInFilter,
  SearchField,
  TotalValue,
} from "../../components/product-table/components";
import {
  Actions,
  Brand,
  Department,
  Description,
  ExtendedCost,
  ItemCode,
  LastReceived,
  MarginPercentage,
  ProductName,
  QOH,
  Reorder,
  Size,
  SizeOunces,
  Type,
  VendorName,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import {
  type ColumnSort,
  multiSort,
} from "../../components/virtualized-table/column-sort";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product, SaleStatDays } from "../../data/product";
import {
  type Filters,
  useFilteredProducts,
  useSortedProducts,
} from "../../hooks/products";

const columns: ColumnDefinition<Product>[] = [
  VendorName,
  ItemCode,
  ProductName,
  Type,
  Size,
  QOH,
  LastReceived,
  ExtendedCost,
  Reorder,
  MarginPercentage,
  Actions,
];

const sortColumns: ColumnSort<Product>[] = [
  {
    column: Department,
    direction: "asc",
  },
  {
    column: SizeOunces,
    direction: "desc",
  },
  {
    column: Type,
    direction: "asc",
  },
  {
    column: Brand,
    direction: "asc",
  },
  {
    column: Description,
    direction: "desc",
  },
];

const defaultFilters: Filters = {
  minimumInventory: 1,
  notSoldIn: 180,
};

const soldInDays: SaleStatDays[] = [60, 90, 180, 365];

export function PhantomInventoryReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );

  const [printing, setPrinting] = useState(false);

  const compareFunction = multiSort(sortColumns);

  const sortedProducts = useSortedProducts(filteredProducts, compareFunction);

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <TotalValue className="mr-auto" />
          <HideDisabledFilter />
          <DepartmentFilter products={products} />
          <NotSoldInFilter overrideSoldInDays={soldInDays} />
          <SearchField />
          <PrimaryButton onClick={() => setPrinting(true)}>
            <PrinterIcon className="h-5 w-5" aria-hidden="true" />
          </PrimaryButton>
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={sortedProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
      {printing && (
        <PrintContainer onAfterPrint={() => setPrinting(false)}>
          <PrintTable columns={columns} items={filteredProducts} />
        </PrintContainer>
      )}
    </>
  );
}
