import { CheckCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import type React from "react";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SignupButton } from "../components/signup-button";

import bridgeUrl from "../assets/bridge.jpeg";
import dashUrl from "../assets/dashboard.jpeg";
import dirkUrl from "../assets/dirk.jpg";
import maxUrl from "../assets/max.jpg";
import negativeUrl from "../assets/negative.jpeg";
import orderingUrl from "../assets/ordering.jpeg";
import phantomUrl from "../assets/phantom.jpeg";
import purchaseUrl from "../assets/po-preview.jpeg";
import productsUrlOne from "../assets/products-1.jpeg";
import productsUrlTwo from "../assets/products-2.jpeg";
import receivingUrlOne from "../assets/receiving-1.jpeg";
import receivingUrlTwo from "../assets/receiving-2.jpeg";
import volumeUrl from "../assets/volume-wide.jpeg";

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (images.length > 1) {
    return (
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Feature ${index + 1}`}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        ))}
      </Slider>
    );
  }

  return (
    <img
      src={images[0]}
      alt={"Feature"}
      className="w-full h-auto rounded-lg shadow-md"
    />
  );
};

const Feature: React.FC<{
  title: string;
  description: string;
  isSelected: boolean;
  onSelected: () => void;
}> = ({ title, description, isSelected, onSelected }) => (
  <div
    className={`p-6 rounded-lg shadow-md cursor-pointer transition duration-150 ${
      isSelected ? "bg-emerald-600 text-white" : "bg-white hover:bg-emerald-50"
    }`}
    onClick={onSelected}
    onKeyDown={onSelected}
  >
    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
    <p className={isSelected ? "text-emerald-50" : "text-gray-600"}>
      {description}
    </p>
  </div>
);

const Testimonial: React.FC<{
  quote: string;
  author: string;
  position: string;
}> = ({ quote, author, position }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="text-gray-600 italic mb-4">"{quote}"</p>
    <div className="flex items-center">
      <UserIcon className="w-10 h-10 text-emerald-500 mr-3" />
      <div>
        <p className="font-semibold">{author}</p>
        <p className="text-gray-500 text-sm">{position}</p>
      </div>
    </div>
  </div>
);

const Founder: React.FC<{
  name: string;
  position: string;
  imageUrl: string;
  children: React.ReactNode;
}> = ({ name, position, imageUrl, children }) => (
  <div className="flex flex-col items-center md:w-1/2">
    <img src={imageUrl} alt={name} className="w-32 h-32 rounded-full mb-4" />
    <h3 className="text-xl font-semibold">{name}</h3>
    <p className="text-gray-500 mb-2">{position}</p>
    <div className="text-gray-500 flex flex-col gap-4">{children}</div>
  </div>
);

interface FeatureData {
  title: string;
  description: string;
  imageUrls: string[];
}

export const PublicHomePage: React.FC = () => {
  const features: FeatureData[] = [
    {
      title: "Automated Ordering",
      description:
        "Smart reordering quantities based on sales velocity and current inventory, optimized to avoid broken-case fees. Fine tune suggested quantities (if needed), then email your purchase order to the vendor directly.",
      imageUrls: [orderingUrl, purchaseUrl],
    },
    {
      title: "Streamlined Receiving",
      description:
        "Convert CSV & PDF invoices from major vendors in AR, OK & NY for direct import into LiquorPOS. Save time & improve accuracy by virtually eliminating manual entry.",
      imageUrls: [receivingUrlOne, receivingUrlTwo],
    },
    {
      title: "Bridge & Volume Buying",
      description:
        "Next-month & volume pricing information for select vendors is integrated with Orderbot's ordering functionality, letting you effortlessly get the best deal on product, directly improving your bottom line.",
      imageUrls: [bridgeUrl, volumeUrl],
    },
    {
      title: "Powerful Reports",
      description:
        "Stay on top of negative & phantom, inventory, under performing products & developping trends. Ordebot's reports make running a tight operation a breeze.",
      imageUrls: [dashUrl, phantomUrl, negativeUrl],
    },
    {
      title: "Available Anywhere",
      description:
        "Need to place a last minute order? How about quickly checking margins for a product family? With Orderbot, you can access key LiquorPOS data from anywhere with internet, place orders & check on your store's performance.",
      imageUrls: [productsUrlOne, productsUrlTwo],
    },
  ];

  const [selectedFeature, setSelectedFeature] = useState<number>(0);

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section className="bg-emerald-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold mb-6">
            Streamline Your Liquor Store Operations with Orderbot
          </h1>
          <p className="text-xl mb-8">
            Automated ordering, streamlined receiving, bridge & volume buying
            for LiquorPOS users.
          </p>
          <SignupButton
            className="py-3 px-6 bg-white text-emerald-600 font-semibold hover:bg-emerald-50"
            label="Start your Free Trial"
          />
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Features</h2>
          <div className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/3 space-y-4">
              {features.map((feature, index) => (
                <Feature
                  key={index}
                  title={feature.title}
                  description={feature.description}
                  isSelected={index === selectedFeature}
                  onSelected={() => setSelectedFeature(index)}
                />
              ))}
            </div>
            <div className="md:w-2/3">
              {features[selectedFeature].imageUrls.length > 0 && (
                <Carousel images={features[selectedFeature].imageUrls} />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-12">Simple, Fair Pricing</h2>
          <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl mx-auto">
            <h3 className="text-2xl font-semibold mb-4">
              $99 or 0.1% of gross revenue
            </h3>
            <p className="text-gray-600 mb-6">
              Whichever is greater, billed monthly. New stores get a 90-day free
              trial.
            </p>
            <ul className="text-left mb-8">
              <li className="flex items-center mb-2">
                <CheckCircleIcon className="w-5 h-5 text-emerald-500 mr-2" />
                <span>All features included</span>
              </li>
              <li className="flex items-center mb-2">
                <CheckCircleIcon className="w-5 h-5 text-emerald-500 mr-2" />
                <span>No long-term contracts</span>
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="w-5 h-5 text-emerald-500 mr-2" />
                <span>Cancel anytime</span>
              </li>
            </ul>
            <SignupButton
              className="py-3 px-6 bg-emerald-600 text-white font-semibold hover:bg-emerald-700"
              label="Start Your 90-Day Free Trial"
            />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            What Our Customers Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <Testimonial
              quote="Orderbot reduces ordering labor in my store by approximately 160 hours per month. Revenue is up 350% over the past 4 years since implementing Orderbot."
              author="Dirk van Veen"
              position="Owner, Mary's Liquor"
            />
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section id="team" className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Meet the Team
          </h2>
          <div className="flex flex-col md:flex-row justify-center items-start space-y-12 md:space-y-0 md:space-x-12">
            <Founder
              name="Dirk van Veen"
              position="Co-Founder | Liquor Store Owner – 16 years"
              imageUrl={dirkUrl}
            >
              <p>
                In Oct. 2018 a law change increased the number of liquor store
                distributors from 1 to 18 and put liquor stores in direct
                competition with chain grocery and convenience stores. This sea
                change significantly increased costs and reduced revenue. Liquor
                stores had to adapt or perish. Ordering and inventory processes
                had to become more efficient.{" "}
              </p>
              <p>
                Ordering time for our 3,500 SKU store went from less than 6
                hours per week to over 40 hours. This exponential increase meant
                that getting our orders done was beyond challenging. The
                required manpower ballooned payroll by an additional $1500 per
                month. We were plagued with outdated software and 14 vendor
                order sites—most of which were poorly engineered and 4
                distributors didn't have order sites. It was time for our “mom
                and pop” liquor store to start operating with the brutal
                efficiency of a grocery chain or perish by their hands.{" "}
              </p>
              <p>
                Our company, Liquor Store Tech, has a mission to create valuable
                business intelligence and process automation products to ensure
                that your liquor store and mine continue to be not only relevant
                but THRIVE in our challenging and ever-changing industry.
              </p>
            </Founder>
            <Founder
              name="Maxime Bury"
              position="Co-Founder | Software Engineer (AWS)"
              imageUrl={maxUrl}
            >
              <p>
                Maxime is originally from France, though perhaps you couldn't
                tell if you heard him. He is an avid traveller, skier, sailor,
                cook who calls Seattle home when he's not on the road.
              </p>
              <p>
                After getting his Master's degree in Computer Science, he's
                spent the next 7 years handcrafting custom software platforms
                for companies of all sizes.
              </p>
              <p>
                Since then, he's moved on to Amazon Web Services, working on
                highly available infrastructure that handles a significant
                fraction of internet's daily traffic.
              </p>
            </Founder>
          </div>
        </div>
      </section>
    </div>
  );
};
