export const Logo = () => (
  <svg
    className="w-10 h-10"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Orderbot</title>
    <rect width="40" height="40" rx="8" fill="#10b981" />
    <path d="M10 30h20M15 10v20M25 10v20" stroke="white" strokeWidth="2" />
    <path
      d="M15 15h10M15 20h10M15 25h10"
      stroke="white"
      strokeWidth="2"
      strokeDasharray="2 2"
    />
    <circle
      cx="20"
      cy="20"
      r="6"
      fill="#10b981"
      stroke="white"
      strokeWidth="2"
    />
    <path d="M18 20h4M20 18v4" stroke="white" strokeWidth="2" />
  </svg>
);
