import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { type MouseEventHandler, useCallback, useState } from "react";
import {
  type Product,
  type SaleStatDays,
  caseDiscount,
  saleStat,
  volumeDiscount,
} from "../../data/product";
import { preciseCurrencyFormatter } from "../../helpers/format-currency";
import { formatDate } from "../../helpers/format-date";
import { EditProduct } from "../../pages/products/edit";
import { SmallSecondaryButton } from "../buttons";
import { Modal } from "../modal";
import { defaultCellClassName } from "../virtualized-table";
import type { ColumnDefinition } from "../virtualized-table/column-definition";

const formatPercent = (percent: number) => {
  return `${(percent * 100).toFixed(1)}%`;
};

const today = new Date();

export const VendorName: ColumnDefinition<Product> = {
  label: "Vendor Name",
  minWidth: 150,
  toString: (product: Product) => product.vendor_name,
  canOverflow: true,
  compareFunction: (a: Product, b: Product) => {
    if (a.vendor_name && !b.vendor_name) {
      return -1;
    }
    if (!a.vendor_name && b.vendor_name) {
      return 1;
    }
    if (a.vendor_name === b.vendor_name) {
      return 0;
    }

    return a.vendor_name < b.vendor_name ? -1 : 1;
  },
};

export const ItemCode: ColumnDefinition<Product> = {
  label: "Item Code",
  minWidth: 50,
  toString: (product: Product) => product.item_code,
  canOverflow: true,
};

export const Barcode: ColumnDefinition<Product> = {
  label: "Barcode",
  minWidth: 100,
  toString: (product: Product) => product.barcode,
  canOverflow: true,
};

export const Brand: ColumnDefinition<Product> = {
  label: "Brand",
  minWidth: 150,
  toString: (product: Product) => product.brand,
  canOverflow: true,
};

export const Description: ColumnDefinition<Product> = {
  label: "Description",
  minWidth: 200,
  toString: (product: Product) => product.description,
  canOverflow: true,
};

export const ProductName: ColumnDefinition<Product> = {
  label: "Product",
  minWidth: 350,
  toString: (product: Product) => `${product.brand} | ${product.description}`,
  canOverflow: true,
};

export const Department: ColumnDefinition<Product> = {
  label: "Department",
  minWidth: 100,
  toString: (product: Product) => product.department,
  canOverflow: true,
};

export const Type: ColumnDefinition<Product> = {
  label: "Type",
  minWidth: 120,
  toString: (product: Product) => product.type,
  canOverflow: true,
};

export const Size: ColumnDefinition<Product> = {
  label: "Size",
  minWidth: 60,
  toString: (product: Product) => product.size,
  canOverflow: false,
  compareFunction: (a: Product, b: Product) => {
    if (a.size_ounces === b.size_ounces) {
      return 0;
    }

    return a.size_ounces < b.size_ounces ? -1 : 1;
  },
};

export const SizeOunces: ColumnDefinition<Product> = {
  label: "Size (oz)",
  minWidth: 60,
  toString: (product: Product) => `${product.size_ounces}`,
  canOverflow: false,
  compareFunction: (a: Product, b: Product) => {
    if (a.size_ounces === b.size_ounces) {
      return 0;
    }

    return a.size_ounces < b.size_ounces ? -1 : 1;
  },
};

export const CaseQty: ColumnDefinition<Product> = {
  label: "Case Qty",
  minWidth: 40,
  toString: (product: Product) => `${product.case_qty}`,
  canOverflow: false,
};

export const StdQty: ColumnDefinition<Product> = {
  label: "Std Qty",
  minWidth: 40,
  toString: (product: Product) => `${product.standard_qty}`,
  canOverflow: false,
};

export const OrderLot: ColumnDefinition<Product> = {
  label: "Order Lot",
  minWidth: 45,
  toString: (product: Product) => product.order_lot,
  canOverflow: false,
};

export const LastCost: ColumnDefinition<Product> = {
  label: "Last Cost",
  minWidth: 60,
  toString: (product: Product) =>
    preciseCurrencyFormatter(product.last_cost * product.standard_qty),
  canOverflow: false,
};

export const LastCaseCost: ColumnDefinition<Product> = {
  label: "Last Case Cost",
  minWidth: 60,
  toString: (product: Product) =>
    preciseCurrencyFormatter(product.last_cost * product.case_qty),
  canOverflow: false,
};

export const CurrentCost: ColumnDefinition<Product> = {
  label: "Frontline Cost",
  minWidth: 85,
  toString: (product: Product) => {
    if (product.pricing.current_cost) {
      return preciseCurrencyFormatter(product.pricing.current_cost);
    }

    return "";
  },
  canOverflow: false,
};

export const FutureCost: ColumnDefinition<Product> = {
  label: "Future Cost",
  minWidth: 70,
  toString: (product: Product) => {
    if (product.pricing.future_cost) {
      return preciseCurrencyFormatter(product.pricing.future_cost);
    }

    return "";
  },
  canOverflow: false,
};

export const Price: ColumnDefinition<Product> = {
  label: "Price",
  minWidth: 60,
  toString: (product: Product) => preciseCurrencyFormatter(product.price),
  canOverflow: false,
};

export const DOH: ColumnDefinition<Product> = {
  label: "DOH",
  minWidth: 60,
  toString: (product: Product) => product.stats.days_on_hand.toFixed(0),
  canOverflow: false,
};

export const QOH: ColumnDefinition<Product> = {
  label: "QOH Units",
  minWidth: 50,
  toString: (product: Product) => `${product.inventory_qty}`,
  canOverflow: false,
  renderer: (_product: Product, stringRepresentation) => {
    return (
      <span className="!text-blue-600 !font-semibold">
        {stringRepresentation}
      </span>
    );
  },
};

export const QOHCases: ColumnDefinition<Product> = {
  label: "QOH Cases",
  minWidth: 50,
  toString: (product: Product) => {
    if (product.case_qty === 0) {
      return "N/A";
    }

    return (product.inventory_qty / product.case_qty).toLocaleString("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  },
  canOverflow: false,
  renderer: (_product: Product, stringRepresentation) => {
    return (
      <span className="!text-blue-600 !font-semibold">
        {stringRepresentation}
      </span>
    );
  },
};

export const Reorder: ColumnDefinition<Product> = {
  label: "Re-order",
  minWidth: 50,
  toString: (product: Product) => (product.disabled ? "❌" : ""),
  canOverflow: false,
};

export const LastReceived: ColumnDefinition<Product> = {
  label: "Last Rec'd",
  minWidth: 60,
  toString: (product: Product) =>
    product.last_received ? formatDate(product.last_received, today) : " -",
  canOverflow: false,
};

export const LastSold: ColumnDefinition<Product> = {
  label: "Last Sold",
  minWidth: 50,
  toString: (product: Product) =>
    product.last_sold ? formatDate(product.last_sold, today) : " -",
  canOverflow: false,
};

export const MarginPercentage: ColumnDefinition<Product> = {
  label: "Margin %",
  minWidth: 60,
  toString: (product: Product) => {
    const marginPercentage =
      (product.price - product.standard_qty * product.last_cost) /
      product.price;
    return formatPercent(marginPercentage);
  },
  canOverflow: false,
};

export const MarginDollars: ColumnDefinition<Product> = {
  label: "Margin $$",
  minWidth: 60,
  toString: (product: Product) =>
    preciseCurrencyFormatter(
      product.price - product.standard_qty * product.last_cost,
    ),
  canOverflow: false,
};

export const SavingsPercentage: ColumnDefinition<Product> = {
  label: "Savings %",
  minWidth: 80,
  toString: (product: Product) => {
    if (!product.pricing.current_cost || !product.pricing.future_cost) {
      return "";
    }

    const savingsPercentage =
      (product.pricing.future_cost - product.pricing.current_cost) /
      product.pricing.future_cost;
    return formatPercent(savingsPercentage);
  },
  renderer: (_product: Product, stringRepresentation) => {
    return (
      <span
        className={
          stringRepresentation.startsWith("-")
            ? "text-red-600"
            : "text-green-600"
        }
      >
        {stringRepresentation}
      </span>
    );
  },
  canOverflow: false,
};

export const SavingsDollars: ColumnDefinition<Product> = {
  label: "Savings $$",
  minWidth: 80,
  toString: (product: Product) => {
    const savings = caseDiscount(product);
    if (savings === 0) {
      return "";
    }

    return preciseCurrencyFormatter(savings);
  },
  canOverflow: false,
};

type IconLabel = {
  icon: string;
  label: string;
};

type IconLabelMap = { [key: string]: IconLabel };

const savingLabels: IconLabelMap = {
  UP: { icon: "↑", label: "Cheaper Next Month" },
  DOWN: { icon: "↓", label: "Cheaper This Month" },
  MONEY: { icon: "💰", label: "Volume Deals" },
  EQUAL: { icon: "", label: "" },
};

export const SavingsIndicator: ColumnDefinition<Product> = {
  label: "",
  minWidth: 30,
  toString: (product: Product) => {
    const swingSavings = caseDiscount(product);
    const volumeSavings = volumeDiscount(product, product.case_qty);

    if (swingSavings === 0 && volumeSavings === 0) {
      return "EQUAL";
    }

    if (swingSavings !== 0) {
      return swingSavings > 0 ? "DOWN" : "UP";
    }

    return "MONEY";
  },
  canOverflow: false,
  renderer: (_product: Product, stringRepresentation) => {
    return (
      <span
        className={`${
          stringRepresentation === "UP" ? "text-red-600" : "text-green-600"
        } text-lg`}
        title={savingLabels[stringRepresentation]?.label}
      >
        {savingLabels[stringRepresentation]?.icon}
      </span>
    );
  },
};

export const ExtendedCost: ColumnDefinition<Product> = {
  label: "Extended Cost",
  minWidth: 80,
  toString: (product: Product) =>
    preciseCurrencyFormatter(product.inventory_qty * product.last_cost),
  canOverflow: false,
};

export function getUnitsSoldColumn(salesStat: SaleStatDays) {
  const unitsSoldColumn: ColumnDefinition<Product> = {
    label: `${salesStat}d Units Sold`,
    minWidth: 50,
    toString: (product: Product) => `${saleStat(product, salesStat)}`,
    className: `${defaultCellClassName} !border-slate-500`,
    canOverflow: false,
  };

  return unitsSoldColumn;
}

export function getCasesSoldColumn(salesStat: SaleStatDays) {
  const casesSoldColumn: ColumnDefinition<Product> = {
    label: `${salesStat}d Cases Sold`,
    minWidth: 50,
    toString: (product: Product) =>
      (saleStat(product, salesStat) / product.case_qty).toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2,
        },
      ),
    className: `${defaultCellClassName} !border-slate-500`,
    canOverflow: false,
    compareFunction: (a: Product, b: Product) => {
      const aCases = saleStat(a, salesStat) / a.case_qty;
      const bCases = saleStat(b, salesStat) / b.case_qty;

      if (aCases === bCases) {
        return 0;
      }

      return aCases < bCases ? -1 : 1;
    },
  };

  return casesSoldColumn;
}

export const Done: ColumnDefinition<Product> = {
  label: "Done",
  minWidth: 50,
  toString: () => "",
  canOverflow: false,
};

export const Actions: ColumnDefinition<Product> = {
  label: "Actions",
  minWidth: 70,
  toString: () => "",
  canOverflow: false,
  renderer: (product) => {
    const [editing, setEditing] = useState(false);

    const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        setEditing(true);
        event.stopPropagation();
      },
      [],
    );

    return (
      <>
        <div>
          <SmallSecondaryButton onClick={onClick} title="Edit">
            <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
          </SmallSecondaryButton>
        </div>
        {editing && (
          <Modal
            title={`${product.brand} | ${product.description}`}
            visible={editing}
            onClose={() => setEditing(false)}
          >
            <EditProduct product={product} onClose={() => setEditing(false)} />
          </Modal>
        )}
      </>
    );
  },
};

export const LineNumber: ColumnDefinition<unknown> = {
  label: "#",
  minWidth: 50,
  toString: () => "",
  canOverflow: false,
  renderer: (_item, _stringRepresentation, row) => {
    return <>{row + 1}</>;
  },
};
