import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  CreditCardIcon,
  PencilSquareIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AlertContext } from "../../contexts/alert-context";
import { StoreContext } from "../../contexts/store-context";
import { useSelectedStore } from "../../hooks/selected-store";
import { StripeService } from "../../services/stripe";
import { uploaderUrl } from "../download-uploader-button";
import { Spinner } from "../spinner";

const menuItemClassName =
  "ui-active:bg-gray-100 inline-flex w-full justify-left px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 gap-3 whitespace-nowrap";

const StoreMenuLink: React.FC<{ href: string; children: React.ReactNode }> = ({
  href,
  children,
}) => {
  const navigate = useNavigate();
  const openLink = useCallback(() => {
    navigate(href);
  }, [href, navigate]);

  return <StoreMenuAction onClick={openLink}>{children}</StoreMenuAction>;
};

// menu item that has an onClick function rather than a link
const StoreMenuAction: React.FC<{
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ onClick, children }) => {
  return (
    <MenuItem as="button" className={menuItemClassName} onClick={onClick}>
      {children}
    </MenuItem>
  );
};

const useLogout = () => {
  const { logout } = useAuth0();
  const logoutAndReturn = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return logoutAndReturn;
};

const useManageBilling = () => {
  const { addErrorAlert } = useContext(AlertContext);
  const [loadingBilling, setLoadingBilling] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const stripeService = useMemo(
    () => new StripeService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { selectedStore } = useSelectedStore();

  const showBilling = useCallback(() => {
    if (!selectedStore) {
      return;
    }

    setLoadingBilling(true);
    stripeService
      .createPortalSession(selectedStore.id)
      .then((response) => {
        window.location.replace(response.portal_url);
      })
      .catch(addErrorAlert);
  }, [stripeService, addErrorAlert, selectedStore]);

  return { showBilling, loadingBilling };
};

export const StoreMenu = () => {
  const { stores } = useContext(StoreContext);
  const { selectedStore, setSelectedStore } = useSelectedStore();
  const logout = useLogout();
  const { showBilling, loadingBilling } = useManageBilling();

  const otherStores = useMemo(() => {
    if (!stores) {
      return [];
    }
    return stores.filter((store) => store.name !== selectedStore?.name);
  }, [stores, selectedStore]);

  // parent component is handling rendering the empty state
  if (!selectedStore || !stores) {
    return;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton
        className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-emerald-700 hover:text-white flex flex-row justify-left items-center gap-1"
        disabled={loadingBilling}
      >
        {selectedStore.name}
        {!loadingBilling && (
          <ChevronDownIcon className="-mr-2 h-5 w-5" aria-hidden="true" />
        )}
        {loadingBilling && <Spinner />}
      </MenuButton>
      <MenuItems className="absolute right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div className="py-1 divide-y divide-gray-100">
          {otherStores.length > 0 && (
            <div>
              <span className="text-sm text-gray-400 p-2">Change Store</span>
              {otherStores.map((store) => (
                <StoreMenuAction
                  key={store.id}
                  onClick={() => {
                    setSelectedStore(store);
                  }}
                >
                  {store.name}
                </StoreMenuAction>
              ))}
            </div>
          )}
          <div>
            <StoreMenuAction onClick={showBilling}>
              <CreditCardIcon className="h-5 w-5" aria-hidden="true" />
              Manage Billing
            </StoreMenuAction>
            <StoreMenuLink href={`/stores/edit/${selectedStore.id}`}>
              <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
              Manage Store
            </StoreMenuLink>
            <StoreMenuLink href="/stores/add">
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
              Add a Store
            </StoreMenuLink>
            <hr />
            <StoreMenuLink href={uploaderUrl}>
              <ArrowDownOnSquareIcon className="h-5 w-5" aria-hidden="true" />
              Download Uploader Tool
            </StoreMenuLink>
            <hr />
            <StoreMenuAction onClick={logout}>
              <ArrowLeftOnRectangleIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
              Logout
            </StoreMenuAction>
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
};
