import { createContext } from "react";
import type { Product } from "../data/product";
import type { Filters } from "../hooks/products";

type ProductTableContext = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  filteredProducts: Product[];
};

export const ProductTableContext = createContext<ProductTableContext>({
  filters: {},
  setFilters: () => {},
  filteredProducts: [],
});
